import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {messages} from "../../Settings";
import ReservedRoomRow from "../../components/reservation/ReservedRoomRow";
import axios from "axios";
import {calculateDifferentDays} from "../../helper/DateHelper";
import {fixedNullableValue, fixedNullableValueFromResponse, parseToFloat, parseToInt} from "../../components/Utils";
import sendRequest from "../../ApiManager/ApiManager";
import {insertFormDataInterface, ReservationGuestInterface} from "../../utils/Entities";
import {setProperty} from "dot-prop";
import {checkErrorHandler, clearErrorForField} from "../../helper/FormErrorHelper";

interface reservedRoomRequestInterface {
    reserved_room_id?: number
    start_date?: string
    end_date?: string,
    room_id?: number,
    room_name?: string,
    price?: number
}

interface reservedRoomPayloadInterface {
    rowId: number,
    data: reservedRoomRequestInterface
}

interface formDataErrors {
    payment_method?: string
    payment_status?: string
    reservation_source?: string
    total_price?: string
    reservation_guest?: ReservationGuestInterface
    description?: string
}

interface reservationMutableInterface {
    payment_method?: string
    payment_status?: string
    reservation_source?: string
    total_price?: number
    reservation_guest?: ReservationGuestInterface
    description?: string
}

interface reservedRoomRowsInterface {
    rowId: number
    component?: JSX.Element,
    data: reservedRoomRequestInterface
    availableRooms: availableRoomsInterface[]
}

interface reservedRoomRowsSettingsInterface {
    counter: number,
    reservedRoomRows: reservedRoomRowsInterface[]
}

interface manageRequestInterface {
    errors: any,
    status: string,
    payload: any,
    requestDate: Date
}

interface initialStateInterface {
    formData: reservationMutableInterface,
    formDataErrors: formDataErrors
    reservedRoomRowsSettings: reservedRoomRowsSettingsInterface,
    manageRequest: manageRequestInterface,
    fetchRequest: fetchReservationInterface
}

interface addReservedRoomRowInterface {
    isDeleteButton: boolean
}

interface fetchAvailableRoomsInterface {
    rowId: number,
    start_date: string,
    end_date: string
}

interface fetchReservationInterface {
    errors: any,
    status: string
    payload: any
}

export interface availableRoomsInterface {
    id: number,
    name: string,
    single_beds: number,
    double_beds: number,
    price_per_day: number,
    description: string | undefined | null,
    created_at: string
}

const defaultReservedRoomRequest: reservedRoomRequestInterface = {
    reserved_room_id: undefined,
    start_date: undefined,
    end_date: undefined,
    room_id: undefined,
    room_name: "Wybierz zakres dat, aby zobaczyć dostępne pokoje",
    price: undefined
}

const bodyToRequest: reservationMutableInterface = {
    payment_method: 'cash',
    payment_status: 'unpaid',
    reservation_source: 'email'
}

const reservedRoomRowsSettings: reservedRoomRowsSettingsInterface = {
    counter: 0,
    reservedRoomRows: []
}

const initialState: initialStateInterface = {
    formData: bodyToRequest,
    formDataErrors: {},
    reservedRoomRowsSettings: reservedRoomRowsSettings,
    manageRequest: {
        errors: null,
        status: 'pending',
        payload: null,
        requestDate: new Date()
    },
    fetchRequest: {
        errors: null,
        status: 'pending',
        payload: null
    }
}

const ManageReservation = createSlice({
    name: 'manageReservation',
    initialState: initialState,
    reducers: {
        clearStore(state) {
            state = initialState

            return state
        },
        addDataToReservedRoom(state, action: PayloadAction<reservedRoomPayloadInterface>) {
            const {rowId, data} = action.payload;

            const index = getIndexOfReservedRoomRow(state, rowId);
            const reservedRoomRow = state.reservedRoomRowsSettings.reservedRoomRows[index]

            const assignData = Object.assign({}, reservedRoomRow.data, data)
            state.reservedRoomRowsSettings.reservedRoomRows[index].data = Object.assign({}, assignData, prepareRoomName(assignData))

            state.formData.total_price = calculateTotalPrice(state)
        },
        insertDataToRequest(state, action: PayloadAction<insertFormDataInterface>) {
            const {field, value} = action.payload

            setProperty(state.formData, field, value)

            if (field.includes("reservation_source") && value?.toString().includes("booking")) {
                state.formData.payment_status = "paid"
                state.formData.payment_method = "booking"
            }
            if (field.includes("payment_method") && value?.toString().includes("booking")) {
                state.formData.payment_status = "paid"
                state.formData.reservation_source = "booking"
            }

            state.formDataErrors = clearErrorForField(state.formDataErrors, field)
        },
        addReservedRoomRow(state, action: PayloadAction<addReservedRoomRowInterface>) {
            const counter = state.reservedRoomRowsSettings.counter

            let reservedRoomRequestData = {}
            if (state.reservedRoomRowsSettings.reservedRoomRows.length > 0) {
                const size = state.reservedRoomRowsSettings.reservedRoomRows.length
                const lastReservedRoomRow = state.reservedRoomRowsSettings.reservedRoomRows[size - 1];
                const lastReservedRoomRowSettings = lastReservedRoomRow.data;

                reservedRoomRequestData = {
                    start_date: lastReservedRoomRowSettings.start_date,
                    end_date: lastReservedRoomRowSettings.end_date,
                    room_name: messages.reservations.selectedDates
                }
            }

            const reservedRoomRow = {
                rowId: counter,
                component: <ReservedRoomRow rowId={counter} deleteButton={action.payload.isDeleteButton}
                                            key={counter}/>,
                data: Object.assign({}, defaultReservedRoomRequest, reservedRoomRequestData),
                availableRooms: []
            }

            state.reservedRoomRowsSettings.reservedRoomRows = [
                ...state.reservedRoomRowsSettings.reservedRoomRows,
                reservedRoomRow
            ]
            state.reservedRoomRowsSettings.counter = counter + 1;
        },
        deleteReservedRoomRow(state, action: PayloadAction<number>) {
            state.reservedRoomRowsSettings.reservedRoomRows = state.reservedRoomRowsSettings.reservedRoomRows.filter(value => value.rowId !== action.payload)

            state.formData.total_price = calculateTotalPrice(state)
        },
        selectRoom(state, action: PayloadAction<{ rowId: number, room: availableRoomsInterface }>) {
            const {rowId, room} = action.payload;

            const index = getIndexOfReservedRoomRow(state, rowId);
            const dataForReservedRoomRow = state.reservedRoomRowsSettings.reservedRoomRows[index].data
            const daysInReservedRoom = calculateDifferentDays(dataForReservedRoomRow?.start_date, dataForReservedRoomRow?.end_date)

            state.reservedRoomRowsSettings.reservedRoomRows[index].data.room_id = room.id
            state.reservedRoomRowsSettings.reservedRoomRows[index].data.room_name = room.name
            state.reservedRoomRowsSettings.reservedRoomRows[index].data.price = room.price_per_day * daysInReservedRoom

            state.formData.total_price = calculateTotalPrice(state)
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAvailableRooms.fulfilled, (state, action) => {
                const rowId: number | undefined = action.payload?.rowId
                const responseData = action.payload?.responseData

                if (undefined === rowId || undefined === responseData) {
                    console.error("Error when fetch available rooms")
                    return;
                }

                const index = getIndexOfReservedRoomRow(state, rowId)

                state.reservedRoomRowsSettings.reservedRoomRows[index].availableRooms = responseData
            })
            .addCase(createReservation.fulfilled, (state, action) => {
                state.manageRequest.status = 'successfully'
                state.manageRequest.payload = action.payload
            })
            .addCase(createReservation.rejected, (state, action) => {
                state.manageRequest.status = 'rejected'
                state.manageRequest.errors = action.payload
                state.formDataErrors = checkErrorHandler(state.formDataErrors, state.manageRequest.errors)
            })
            .addCase(updateReservation.fulfilled, (state, action) => {
                state.manageRequest.status = 'successfully'
                state.manageRequest.payload = action.payload
                state.manageRequest.requestDate = new Date()
            })
            .addCase(updateReservation.rejected, (state, action) => {
                state.manageRequest.status = 'rejected'
                state.manageRequest.errors = action.payload
                state.manageRequest.requestDate = new Date()
                state.formDataErrors = checkErrorHandler(state.formDataErrors, state.manageRequest.errors)
            })
            .addCase(fetchReservation.fulfilled, (state, action) => {
                state.fetchRequest.status = 'successfully'
                state.fetchRequest.payload = action.payload

                for (let i = 0; i < action.payload.reserved_rooms.length; i++) {
                    const counter = state.reservedRoomRowsSettings.counter
                    const reserved_room = action.payload.reserved_rooms[i]
                    const reservedRoomRow = {
                        rowId: counter,
                        component: <ReservedRoomRow rowId={counter} deleteButton={0 !== i}
                                                    key={counter}/>,
                        data: {
                            reserved_room_id: reserved_room.id,
                            start_date: reserved_room.start_date,
                            end_date: reserved_room.end_date,
                            room_id: reserved_room.room_id,
                            room_name: reserved_room.room_name,
                            price: reserved_room.price
                        },
                        availableRooms: []
                    }

                    state.reservedRoomRowsSettings.reservedRoomRows = [
                        ...state.reservedRoomRowsSettings.reservedRoomRows,
                        reservedRoomRow
                    ]
                    state.reservedRoomRowsSettings.counter = counter + 1;
                }

                state.formData = {
                    payment_method: action.payload.payment_method,
                    payment_status: action.payload.payment_status,
                    reservation_source: action.payload.reservation_source,
                    total_price: action.payload.total_price,
                    reservation_guest: action.payload.reservation_guest,
                    description: fixedNullableValueFromResponse(action.payload.description)
                }
            })
            .addCase(fetchReservation.rejected, (state, action) => {
                state.fetchRequest.status = 'rejected'
                // @ts-ignore
                state.fetchRequest.errors = action.payload
            })
    }
})

export const fetchAvailableRooms = createAsyncThunk('get/availableRooms', async (settings: fetchAvailableRoomsInterface, thunkAPI) => {
    try {
        const response = await sendRequest('get', '/rooms/available?startDate=' + settings.start_date + '&endDate=' + settings.end_date);
        return {
            rowId: settings.rowId,
            responseData: response.data
        }
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const fetchReservation = createAsyncThunk('get/reservation', async (reservationId: number, thunkAPI) => {
    try {
        const response = await sendRequest('get', '/reservations/' + reservationId);
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const deleteReservation = createAsyncThunk('delete/reservation', async (reservationId: number, thunkAPI) => {
    try {
        const response = await sendRequest('delete', '/reservations/' + reservationId);
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const createReservation = createAsyncThunk('post/createReservation', async (formData: initialStateInterface, thunkAPI) => {
    try {
        const response = await sendRequest('post', '/reservations', prepareDataToRequest(formData));
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const updateReservation = createAsyncThunk('post/updateReservation', async (formData: initialStateInterface, thunkAPI) => {
    const reservationId = formData.fetchRequest.payload.id
    try {
        const response = await sendRequest('post', '/reservations/' + reservationId, prepareDataToRequest(formData));
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

const prepareRoomName = (state: reservedRoomRequestInterface) => {
    if (undefined === state) {
        return;
    }
    if (state.room_name !== messages.reservations.selectedDates && state.room_name !== messages.reservations.notSelectedDates) {
        return state
    }
    if (undefined !== state.start_date && undefined !== state.end_date) {
        state.room_name = messages.reservations.selectedDates
    } else {
        state.room_name = messages.reservations.notSelectedDates
    }

    return state;
}

const prepareDataToRequest = (state: initialStateInterface) => {

    let reserved_rooms: object[] = []

    for (let i = 0; i < state.reservedRoomRowsSettings.reservedRoomRows.length; i++) {
        const data = state.reservedRoomRowsSettings.reservedRoomRows[i].data

        const reserved_room = {
            reserved_room_id: undefined !== data.reserved_room_id
                ? parseToInt(data.reserved_room_id)
                : null,
            room_id: parseToInt(data.room_id),
            start_date: data.start_date,
            end_date: data.end_date,
            price: parseToFloat(data.price)
        }

        reserved_rooms = [...reserved_rooms, reserved_room]
    }

    const phoneNumber = fixedNullableValue(state.formData.reservation_guest?.phone_number)

    return {
        payment_method: state.formData.payment_method,
        payment_status: state.formData.payment_status,
        reservation_source: state.formData.reservation_source,
        total_price: parseToFloat(state.formData.total_price),
        reservation_guest: {
            name: fixedNullableValue(state.formData.reservation_guest?.name),
            email_address: fixedNullableValue(state.formData.reservation_guest?.email_address),
            phone_number: phoneNumber?.replace(/[^\d+]/g, "")
        },
        reserved_rooms: reserved_rooms,
        description: fixedNullableValue(state.formData.description)
    }
}

const getIndexOfReservedRoomRow = (state: initialStateInterface, rowId: number): number => {
    const reservedRoomRowProxy = state.reservedRoomRowsSettings.reservedRoomRows.find(value => value.rowId === rowId)

    if (undefined === reservedRoomRowProxy) {
        throw new Error("Not Found")
    }

    return state.reservedRoomRowsSettings.reservedRoomRows.indexOf(reservedRoomRowProxy);
}

const calculateTotalPrice = (state: initialStateInterface): number => {
    let totalPrice: number = 0;

    for (let i = 0; i < state.reservedRoomRowsSettings.reservedRoomRows.length; i++) {
        const pricePerReservedRoom = state.reservedRoomRowsSettings.reservedRoomRows[i].data.price;
        if (pricePerReservedRoom) {
            totalPrice += parseToFloat(pricePerReservedRoom)
        }
    }

    return totalPrice;
}

export const manageReservationActions = ManageReservation.actions;

export default ManageReservation;