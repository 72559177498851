import {alertType} from "../utils/TypeUtils";
import {useState} from "react";
import Alert from "../components/alert/Alert";

export const useAlert = () => {
    const [alerts, setAlerts] = useState<JSX.Element[]>([])

    const addAlert = (alertType: alertType, message: string, secondsToHide?: number) => {
        const alert = <Alert
            type={alertType}
            message={message}
            secondsToHide={secondsToHide ?? 10}

            key={Math.random()}
        />

        setAlerts([...alerts, alert])
    }

    return {alerts, addAlert}
}