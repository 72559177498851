import React, {useEffect, useState} from "react";
import Input from "../Input";
import Textarea from "../Textarea";
import FormButton from "../FormButton";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import FormRow from "../FormRow";
import Select from "../Select";
import {
    createReservation,
    deleteReservation,
    manageReservationActions,
    updateReservation
} from "../../../redux/features/ManageReservation";
import {getResponseErrorMessage} from "../../utils/ErrorAlert";
import {messages, ROUTE_RESERVATION_CREATE} from "../../../Settings";
import Modal from "../../modal/Modal";
import {useNavigate} from "react-router-dom";
import {useAlert} from "../../../hooks/useAlert";

interface ReservationFormProps {
    reservationId?: number
}

function RoomForm(reservationFormProps: ReservationFormProps) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const manageReservation = useAppSelector(state => state.manageReservation)
    const {alerts, addAlert} = useAlert()

    const formButtonMessage = undefined === reservationFormProps.reservationId
        ? messages.reservations.createButton
        : messages.reservations.updateButton
    const successMessage = undefined === reservationFormProps.reservationId
        ? messages.reservations.create
        : messages.reservations.update

    const [modal, setModal] = useState<JSX.Element>()


    const handleAddReservedRoomRow = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        dispatch(manageReservationActions.addReservedRoomRow({isDeleteButton: true}))
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target

        dispatch(manageReservationActions.insertDataToRequest({
            field: name,
            value: value
        }))
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        if (undefined === reservationFormProps.reservationId) {
            dispatch(createReservation(manageReservation))
        } else {
            dispatch(updateReservation(manageReservation))
        }
    }

    const handleDeleteReservation = (event: React.MouseEvent<HTMLButtonElement>) => {
        dispatch(deleteReservation(reservationFormProps.reservationId ?? 0))
        navigate(ROUTE_RESERVATION_CREATE)
    }

    const handleDeleteReservationModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        setModal(<Modal
            message={"Czy chcesz usunąć wybraną rezerwację?"}
            confirmAction={handleDeleteReservation}
            key={Math.random()}
        />)
    }

    const deleteButton = undefined !== reservationFormProps.reservationId ? (
        <div className={"w-full md:w-1/4"}>
            <FormButton
                type={"danger"}
                buttonType={"button"}
                text={"Usuń rezerwację"}
                handleClick={handleDeleteReservationModal}
            />
        </div>
    ) : (<div></div>)


    useEffect(() => {
        if ('successfully' === manageReservation.manageRequest.status) {
            addAlert(
                "success",
                successMessage
            )
        } else if ('rejected' === manageReservation.manageRequest.status) {
            addAlert(
                "danger",
                getResponseErrorMessage(manageReservation.manageRequest.errors)
            )
        }
    }, [manageReservation.manageRequest.errors, manageReservation.manageRequest.status, manageReservation.manageRequest.requestDate, successMessage])

    return (
        <div>
            {alerts}
            {modal}
            <form className={"w-full flex flex-col"}>
                <>
                    {
                        manageReservation.reservedRoomRowsSettings.reservedRoomRows.map(value => {
                            return value.component
                        })
                    }
                </>
                <FormRow>
                    <FormButton
                        type={"warning"}
                        text={"Dodaj pokój"}
                        buttonType={"button"}
                        handleClick={handleAddReservedRoomRow}
                    />
                </FormRow>
                <FormRow>
                    <div className={"w-full xl:w-1/3"}>
                        <Input
                            type={"text"}
                            name={"reservation_guest.name"}
                            title={"Imię/Nazwa firmy klienta"}
                            isRequired={false}
                            placeholder={"Jan Kowalski"}
                            defaultValue={manageReservation.formData.reservation_guest?.name}
                            errorMessage={manageReservation.formDataErrors.reservation_guest?.name}
                            onChange={handleInput}
                        />
                    </div>
                    <div className={"w-full xl:w-1/3"}>
                        <Input
                            type={"text"}
                            name={"reservation_guest.phone_number"}
                            title={"Numer telefonu klienta"}
                            isRequired={false}
                            placeholder={"+48383934453"}
                            defaultValue={manageReservation.formData.reservation_guest?.phone_number}
                            errorMessage={manageReservation.formDataErrors.reservation_guest?.phone_number}
                            onChange={handleInput}
                        />
                    </div>
                    <div className={"w-full xl:w-1/3"}>
                        <Input
                            type={"text"}
                            name={"reservation_guest.email_address"}
                            title={"Adres e-mail klienta"}
                            isRequired={false}
                            placeholder={"example@gmail.com"}
                            defaultValue={manageReservation.formData.reservation_guest?.email_address}
                            errorMessage={manageReservation.formDataErrors.reservation_guest?.email_address}
                            onChange={handleInput}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className={"w-full xl:w-1/4"}>
                        <Select
                            name={"payment_method"}
                            title={"Metoda płatności"}
                            options={[
                                {
                                    value: "cash",
                                    text: "Gotówka"
                                },
                                {
                                    value: "transfer",
                                    text: "Przelew"
                                },
                                {
                                    value: "booking",
                                    text: "Opłacono w Booking.com"
                                }
                            ]}
                            isRequired={true}
                            onChange={handleInput}
                            value={manageReservation.formData.payment_method}
                            errorMessage={manageReservation.formDataErrors.payment_method}
                        />
                    </div>
                    <div className={"w-full xl:w-1/4"}>
                        <Select
                            name={"payment_status"}
                            title={"Status płatności"}
                            options={[
                                {
                                    value: "unpaid",
                                    text: "Niepłacono"
                                },
                                {
                                    value: "prepaid",
                                    text: "Wpłacono zaliczkę"
                                },
                                {
                                    value: "paid",
                                    text: "Opłacono"
                                }
                            ]}
                            isRequired={true}
                            onChange={handleInput}
                            value={manageReservation.formData.payment_status}
                            errorMessage={manageReservation.formDataErrors.payment_status}
                        />
                    </div>
                    <div className={"w-full xl:w-1/4"}>
                        <Select
                            name={"reservation_source"}
                            title={"Źródło rezerwacji"}
                            options={[
                                {
                                    value: "email",
                                    text: "Wiadomość email"
                                },
                                {
                                    value: "facebook",
                                    text: "Wiadomość z Facebook/Messenger"
                                },
                                {
                                    value: "phone",
                                    text: "Wiadomość telefoniczna"
                                },
                                {
                                    value: "booking",
                                    text: "Rezerwacja z Booking.com"
                                }
                            ]}
                            isRequired={true}
                            onChange={handleInput}
                            value={manageReservation.formData.reservation_source}
                            errorMessage={manageReservation.formDataErrors.reservation_source}
                        />
                    </div>
                    <div className={"w-full xl:w-1/4"}>
                        <Input
                            type={"number"}
                            name={"total_price"}
                            title={"Całkowita kwota rezerwacji"}
                            isRequired={true}
                            placeholder={"235.00"}
                            defaultValue={manageReservation.formData.total_price}
                            errorMessage={manageReservation.formDataErrors.total_price}
                            onChange={handleInput}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div className={"w-full xl:w-full"}>
                        <Textarea
                            name={"description"}
                            title={"Dodatkowy opis rezerwacji, notatki"}
                            rows={5}
                            placeholder={"Dodatkowe ręczniki w pokoju"}
                            defaultValue={manageReservation.formData.description}
                            errorMessage={manageReservation.formDataErrors.description}
                            onChange={handleInput}
                        />
                    </div>
                </FormRow>
                <div className={"w-full flex space-x-2 justify-between"}>
                    {deleteButton}
                    <div className={"w-full md:w-1/4"}>
                        <FormButton
                            type={"success"}
                            text={formButtonMessage}
                            handleClick={handleSubmit}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default RoomForm;