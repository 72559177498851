import {useState} from "react";
import sendRequest from "../ApiManager/ApiManager";
import {ReservationInterface} from "../utils/Entities";

const useCalendarDrawer = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [reservation, setReservation] = useState<ReservationInterface>()

    const handleCloseAction = () => {
        setIsVisible(false)
    }

    const handleGetReservationAction = (reservationId: number) => {
        sendRequest('get', `/reservations/${reservationId}`)
            .then(response => {
                setReservation(response.data)
                setIsVisible(true)
            })
            .catch(() => {
                setReservation(undefined)
                setIsVisible(false)
            })
    }

    return {isVisible, reservation, handleCloseAction, handleGetReservationAction}
}

export default useCalendarDrawer