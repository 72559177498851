import moment from "moment";

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const NORMAL_DATE_FORMAT = 'DD.MM.YYYY'
export const NORMAL_DATE_FORMAT_WITH_TIME = 'DD.MM.YYYY HH:mm:ss'

const fixedDateFormat = (date: Date): string => {
    const month = date.getMonth() + 1
    const day = date.getDate()

    const fixedMonth = month < 10 ? "0" + month : month
    const fixedDay = day < 10 ? "0" + day : day

    return date.getFullYear() + "-" + fixedMonth + "-" + fixedDay
}

export const calculateMinDate = (value: string | undefined): string | undefined => {
    if (undefined === value) {
        return undefined;
    }
    const dateFromValue = new Date(value)
    dateFromValue.setDate(dateFromValue.getDate() + 1)

    return fixedDateFormat(dateFromValue)
}

export const calculateMaxDate = (value: string | undefined): string | undefined => {
    if (undefined === value) {
        return undefined;
    }
    const dateFromValue = new Date(value)
    dateFromValue.setDate(dateFromValue.getDate() - 1)

    return fixedDateFormat(dateFromValue)
}

export const calculateDifferentDays = (startDate: string | undefined, endDate: string | undefined): number => {
    if(undefined === startDate || undefined === endDate) {
        return 0;
    }

    const startDateMoment = moment(startDate)
    const endDateMoment = moment(endDate)

    return endDateMoment.diff(startDateMoment, 'days')
}