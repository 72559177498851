import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import sendRequest from "../../ApiManager/ApiManager";


interface manageRequestInterface {
    status: string,
    payload: any,
    errors: any,
    time: Date
}

interface bodyToAuthorizationInterface {
    username?: string,
    password?: string
}

interface authInterface {
    currentUser: any,
    isLoggedIn: boolean,
    manageRequest: manageRequestInterface,
    bodyToAuthorization: bodyToAuthorizationInterface
}

const initialState: authInterface = {
    currentUser: null,
    isLoggedIn: false,
    manageRequest: {
        errors: null,
        status: 'pending',
        payload: undefined,
        time: moment().toDate()
    },
    bodyToAuthorization: {
        username: undefined,
        password: undefined,
    }
}

const authorizationManage = createSlice({
    name: "Auth",
    initialState: initialState,
    reducers: {
        logout(state) {
            state = initialState

            return state
        },
        insertDataToBody(state, action: PayloadAction<bodyToAuthorizationInterface>) {
            state.bodyToAuthorization = Object.assign({}, state.bodyToAuthorization, action.payload)
        }
    },
    extraReducers(builder) {
        builder
            .addCase(authorizationUser.pending, (state, action) => {
                state.manageRequest.status = "pending"
            })
            .addCase(authorizationUser.fulfilled, (state, action) => {
                state.isLoggedIn = true
                state.currentUser = action.payload
                state.manageRequest.status = 'successfully'
                localStorage.setItem("vr_user", JSON.stringify(action.payload))
            })
            .addCase(authorizationUser.rejected, (state, action) => {
                state.manageRequest.errors = action.payload
                state.manageRequest.status = 'rejected'
            })
    }
})

export const authorizationUser = createAsyncThunk('authorization/user', async (state: authInterface, thunkAPI) => {
    try {
        const response = await sendRequest(
            'post',
            '/login',
            state.bodyToAuthorization,
            true
        )
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
});

export const manageAuthActions = authorizationManage.actions;

export default authorizationManage