import React, {useEffect, useRef, useState} from "react";
import {getAlertColorConfiguration} from "../../utils/Utils";
import {alertType} from "../../utils/TypeUtils";
import ReactMarkdown from "react-markdown";

interface alertInterface {
    type: alertType
    message: string
    secondsToHide: number
}

const Alert = (alert: alertInterface) => {
    const [visible, setVisible] = useState(true);
    const [timer, setTimer] = useState(alert.secondsToHide)

    const interval = useRef<any>(null);

    const hideComponent = () => {
        clearInterval(interval.current)
        interval.current = null
        setVisible(false)
    }

    useEffect(() => {
        if (timer > 0) {
            interval.current = setInterval(() => {
                setTimer(timer - 1)
            }, 1000)
        } else {
            hideComponent()
        }
        return () => clearInterval(interval.current)
    }, [timer])

    if (!visible) {
        return null;
    }

    const alertColorConfiguration = getAlertColorConfiguration(alert.type);

    const handleHideAlert = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        hideComponent()
    };

    return (
        <div
             className={"flex p-3 md:p-4 mb-4 " + alertColorConfiguration + " rounded-lg items-center"}
             role={"alert"}>
            <i className={"fa-solid fa-circle-info"}></i>
            <div className={"ml-3 text-sm font-medium"}>
                <ReactMarkdown>{alert.message}</ReactMarkdown>
            </div>
            <div className={"ml-auto flex"}>
                <span className={"mr-3 opacity-50 text-sm"}>({timer} sek)</span>
                <button
                    type="button"
                    className={"-mx-1.5 -my-1.5 text-gray-600 hover:text-gray-800 rounded-lg p-1.5 inline-flex h-8 w-8 items-center justify-center hover:bg-gray-400 hover:bg-opacity-60"}
                    aria-label="Close"
                    onClick={handleHideAlert}
                >
                    <span className="sr-only">Close</span>
                    <i className="fa-solid fa-xmark text-xl"></i>
                </button>
            </div>
        </div>
    );
}

export default Alert;