import React from "react";

interface errorLabelInterface {
    errorMessage?: string
}

const ErrorLabel = (errorLabel: errorLabelInterface) => {
    return errorLabel.errorMessage ? <div className={"text-sm text-red-500 pt-2"}>{errorLabel.errorMessage}</div> : null
}

export default ErrorLabel