import React, {useEffect, useState} from "react";
import Input from "../Input";
import Textarea from "../Textarea";
import FormButton from "../FormButton";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {
    manageRoomActions,
    sendCreateRoomRequest,
    sendDeleteRoomRequest,
    sendUpdateRoomRequest
} from "../../../redux/features/RoomManage";
import {getResponseErrorMessage} from "../../utils/ErrorAlert";
import {messages, ROUTE_ROOM_CREATE} from "../../../Settings";
import Modal from "../../modal/Modal";
import {useNavigate} from "react-router-dom";
import {useAlert} from "../../../hooks/useAlert";

interface RoomFormProps {
    roomId?: number
}

function RoomForm(roomFormProps: RoomFormProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const roomManage = useAppSelector((state) => state.roomManage)
    const {alerts, addAlert} = useAlert()

    const isUpdateMode = undefined !== roomFormProps.roomId

    const formButtonMessage = undefined === roomFormProps.roomId
        ? messages.rooms.createButton
        : messages.rooms.updateButton
    const successMessage = undefined === roomFormProps.roomId
        ? messages.rooms.create
        : messages.rooms.update

    const [modal, setModal] = useState<JSX.Element>()

    useEffect(() => {
        if ('successfully' === roomManage.requestData.status) {
            addAlert("success", successMessage)
        } else if ('rejected' === roomManage.requestData.status) {
            addAlert("danger", getResponseErrorMessage(roomManage.requestData.payload))
        }
    }, [roomManage.requestData])

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()

        if (undefined !== roomFormProps.roomId) {
            dispatch(sendUpdateRoomRequest({
                roomId: roomFormProps.roomId,
                bodyToRequest: roomManage.formData
            }))
        } else {
            dispatch(sendCreateRoomRequest(roomManage.formData))
        }
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputName = event.target.name
        const inputValue = event.target.value

        dispatch(manageRoomActions.insertFormData({
            field: inputName,
            value: inputValue
        }))
    }

    const handleDeleteRoom = () => {
        dispatch(sendDeleteRoomRequest(roomFormProps.roomId ?? 0))
        navigate(ROUTE_ROOM_CREATE)
    }

    const handleDeleteRoomModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        setModal(<Modal
            message={"Czy chcesz usunąć pokój o nazwie " + roomManage.formData.name}
            confirmAction={handleDeleteRoom}
            key={Math.random()}
        />)
    }

    const deleteButton = isUpdateMode ? (
        <div className={"w-full md:w-1/4"}>
            <FormButton
                type={"danger"}
                buttonType={"button"}
                text={"Usuń pokój"}
                handleClick={handleDeleteRoomModal}
            />
        </div>
    ) : (<div></div>)
    return (
        <div>
            {alerts}
            {modal}
            <form className={"w-full flex flex-col"} autoComplete={"off"} onSubmit={handleSubmit}>
                <div className={"w-full mb-5 flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3"}>
                    <div className={"w-full"}>
                        <Input
                            type={"text"}
                            name={"name"}
                            title={"Nazwa pokoju"}
                            placeholder={"Wprowadź nazwę pokoju, minimalnie 3, maksymalnie 255 znaków"}
                            isRequired={true}
                            defaultValue={roomManage.formData.name}
                            errorMessage={roomManage.formDataErrors.name}
                            onChange={handleInput}
                        />
                    </div>
                </div>
                <div className={"w-full mb-5 flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3"}>
                    <div className={"w-full md:w-1/3"}>
                        <Input
                            type={"number"}
                            name={"single_beds"}
                            title={"Liczba łóżek jednoosobowych"}
                            placeholder={"1"}
                            isRequired={true}
                            defaultValue={roomManage.formData.single_beds}
                            errorMessage={roomManage.formDataErrors.single_beds}
                            min={0}
                            onChange={handleInput}
                        />
                    </div>
                    <div className={"w-full md:w-1/3"}>
                        <Input
                            type={"number"}
                            name={"double_beds"}
                            title={"Liczba łóżek dwuosobowych"}
                            placeholder={"2"}
                            isRequired={true}
                            defaultValue={roomManage.formData.double_beds}
                            errorMessage={roomManage.formDataErrors.double_beds}
                            min={0}
                            onChange={handleInput}
                        />
                    </div>
                    <div className={"w-full md:w-1/3"}>
                        <Input
                            type={"number"}
                            name={"price_per_day"}
                            title={"Kwota za dobę"}
                            placeholder={"250.00"}
                            isRequired={true}
                            defaultValue={roomManage.formData.price_per_day}
                            errorMessage={roomManage.formDataErrors.price_per_day}
                            min={0}
                            onChange={handleInput}
                        />
                    </div>
                </div>
                <div className={"w-full mb-5 flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3"}>
                    <div className={"w-full"}>
                        <Textarea
                            name={"description"}
                            title={"Notatki do pokoju"}
                            placeholder={"Miejsce na Twoje notatki, np informacje o balkonie"}
                            rows={5}
                            defaultValue={roomManage.formData.description}
                            errorMessage={roomManage.formDataErrors.description}
                            onChange={handleInput}
                        />
                    </div>
                </div>
                <div className={"w-full flex space-x-2 justify-between"}>
                    {deleteButton}
                    <div className={"w-full float-right md:w-1/4"}>
                        <FormButton
                            type={"success"}
                            text={formButtonMessage}
                            buttonType={"submit"}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default RoomForm;