import {IconProp} from "@fortawesome/fontawesome-svg-core";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import NavigateList from "./NavigateList";
import {NavigateDropdownItemInterface} from "./NavigateDropdownItem";
import {useLocation} from "react-router-dom";
import NavigateIcon from "../../atoms/NavigateIcon";

export interface NavigateDropdownInterface {
    text: string
    icon?: IconProp,
    children: React.ReactElement<NavigateDropdownItemInterface>[] | React.ReactElement<NavigateDropdownItemInterface>
}

const NavigateDropdown = ({children, text, icon}: NavigateDropdownInterface) => {
    const location = useLocation()
    const [isHidden, setHidden] = useState(true);

    const handleChangeHiddenState = () => {
        setHidden(!isHidden)
    }

    useEffect(() => {
        if (Array.isArray(children)) {
            children.map(child => {
                if ("/dashboard" + child.props.location === location.pathname) {
                    setHidden(false)
                }
            })
        } else if ("/dashboard" + children.props.location === location.pathname) {
            setHidden(false)
        }
    }, [location.pathname, children])

    return (
        <li>
            <button
                className={(!isHidden ? "text-white bg-gray-700" : "text-gray-400 hover:bg-gray-700 hover:text-white")
                    + " flex items-center p-2 rounded-lg group w-full"}
                onClick={handleChangeHiddenState}
            >
                <NavigateIcon icon={icon}/>
                <span className="ml-3 flex-1 transition duration-75 text-left">{text}</span>
                <FontAwesomeIcon
                    icon={isHidden ? faCaretDown : faCaretUp}
                    className={"w-4 h-4 ease-in-out duration-300 group-hover:text-white"}/>
            </button>
            <NavigateList className={(isHidden && "hidden opacity-0 ") + " py-2"}>
                {children}
            </NavigateList>
        </li>
    )
}

export default NavigateDropdown