import Input from "../form/Input";
import FormRow from "../form/FormRow";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    availableRoomsInterface,
    fetchAvailableRooms,
    manageReservationActions
} from "../../redux/features/ManageReservation";
import {calculateMaxDate, calculateMinDate} from "../../helper/DateHelper";
import HelpLabel from "../form/HelpLabel";
import {messages} from "../../Settings";
import AvailableRoom from "./AvailableRoom";
import useClickOutside from "../../hooks/useClickOutside";

interface ReservedRoomRowProps {
    rowId: number
    deleteButton: boolean
}

function ReservedRoomRow(reservedRoomRowProps: ReservedRoomRowProps) {
    const manageReservation = useAppSelector(state => state.manageReservation)
    const dispatch = useAppDispatch()
    const {ref, visible, setVisible, status} = useClickOutside()

    const deleteHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
        dispatch(manageReservationActions.deleteReservedRoomRow(reservedRoomRowProps.rowId))
    }

    const mobileMargin = reservedRoomRowProps.deleteButton ? "mt-5 xl:mt-0" : "";
    const inputNamePrefix = "reserved_room[" + reservedRoomRowProps.rowId + "]."
    const deletedButton =
        (<div className={"w-full xl:w-16 flex items-end justify-center pt-1.5 xl:pt-0 pb-1.5"}>
            <button
                className={"text-gray-300"}
                onClick={deleteHandle}
            >
                <i className="fa-solid fa-trash fa-xl"></i>
            </button>
        </div>);

    const reservedRoom = manageReservation.reservedRoomRowsSettings.reservedRoomRows.find(value => value.rowId === reservedRoomRowProps.rowId)

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const fieldName = name.split(".")[1]

        const dataToDispatch = {
            rowId: reservedRoomRowProps.rowId,
            data: {
                [fieldName]: event.target.value
            }
        }

        dispatch(manageReservationActions.addDataToReservedRoom(dataToDispatch))
    }

    const handleToggleAvailableRoomsSelector = (event: React.MouseEvent<HTMLInputElement>) => {
        if (messages.reservations.notSelectedDates !== reservedRoom?.data.room_name) {
            setVisible(!visible)
        }
    }

    const handleSelectAvailableRoom = (event: React.MouseEvent, availableRoom: availableRoomsInterface) => {
        dispatch(manageReservationActions.selectRoom({
            rowId: reservedRoomRowProps.rowId,
            room: availableRoom
        }))
        setVisible(false)
    }

    useEffect(() => {
        if (undefined !== reservedRoom?.data.start_date && undefined !== reservedRoom?.data.end_date) {
            dispatch(fetchAvailableRooms({
                rowId: reservedRoomRowProps.rowId,
                start_date: reservedRoom.data.start_date,
                end_date: reservedRoom.data.end_date,
            }))
        }
    }, [dispatch, reservedRoom?.data.end_date, reservedRoom?.data.start_date, reservedRoomRowProps.rowId])

    return (
        <FormRow>
            <div className={"w-full xl:w-2/12 " + mobileMargin}>
                <Input
                    type={"date"}
                    name={inputNamePrefix + "start_date"}
                    title={"Data początku rezerwacji"}
                    isRequired={true}
                    defaultValue={reservedRoom?.data.start_date}
                    max={calculateMaxDate(reservedRoom?.data.end_date)}
                    onChange={handleInput}
                />
            </div>
            <div className={"w-full xl:w-2/12"}>
                <Input
                    type={"date"}
                    name={inputNamePrefix + "end_date"}
                    title={"Data końca rezerwacji"}
                    isRequired={true}
                    defaultValue={reservedRoom?.data.end_date}
                    min={calculateMinDate(reservedRoom?.data.start_date)}
                    onChange={handleInput}
                />
            </div>
            <div className={"w-full xl:w-6/12"}>
                <div>
                    <HelpLabel
                        name={inputNamePrefix + "name"}
                        title={"Wybrany pokój do rezerwacji w tym czasie"}
                        required={true}/>
                    <div
                        className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-none placeholder-gray-400 text-gray-100 focus:ring-2 focus:ring-orange-400 outline-none"
                        onClick={handleToggleAvailableRoomsSelector}
                        key={reservedRoomRowProps.rowId}
                        id={inputNamePrefix + "name"}
                    >
                        {reservedRoom?.data.room_name}
                    </div>
                </div>
                <div className={status + " relative -top-1 w-full"} ref={ref}>
                    <div className={"absolute space-y-3 p-4 w-full bg-gray-600 text-gray-300 h-auto xl:h-72 overflow-y-scroll"}>
                        {
                            reservedRoom?.availableRooms.map(value => {
                                return <AvailableRoom
                                    key={Math.random() + value.id}
                                    roomId={value.id}
                                    name={value.name}
                                    singleBeds={value.single_beds}
                                    doubleBeds={value.double_beds}
                                    pricePerDay={value.price_per_day}
                                    onClick={event => handleSelectAvailableRoom(event, value)}
                                />
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={"w-full xl:w-2/12"}>
                <Input
                    type={"number"}
                    name={inputNamePrefix + "price"}
                    title={"Kwota za wybraną konfigurację"}
                    defaultValue={reservedRoom?.data.price}
                    isRequired={true}
                    onChange={handleInput}
                    step={0.01}
                    min={0}
                />
            </div>
            <>
                {reservedRoomRowProps.deleteButton && deletedButton}
            </>
        </FormRow>
    )
}

export default ReservedRoomRow