import React, {useEffect, useRef, useState} from "react";

function useClickOutside() {
    const ref = useRef<HTMLDivElement>(null)
    const [visible, setVisible] = useState(false);

    let status = visible ? 'block' : 'hidden';

    const handleHideComponent = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setVisible(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleHideComponent, true)
        return () => {
            document.removeEventListener('click', handleHideComponent, true)
        }
    }, [])

    const handleClick = (event: React.MouseEvent) => {
        event.preventDefault();
        setVisible(!visible);
    };

    return {visible, setVisible, ref, status, handleClick}
}

export default useClickOutside