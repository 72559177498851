import {MouseEventHandler} from "react";

export type colorType = "light-red" | "red" | "green" | "orange" | "light-orange" | "blue" | "yellow"

interface CalendarBadgeInterface {
    color: colorType
    text: string
    action?: MouseEventHandler
}

const CalendarBadge = ({color, text, action}: CalendarBadgeInterface) => {
    let classParameters: string = "py-0.5 px-2 rounded-lg text-sm"

    switch (color) {
        case "light-red":
            classParameters += " bg-red-400 text-red-50"
            break;
        case "red":
            classParameters += " bg-red-700 text-red-200"
            break;
        case "green":
            classParameters += " bg-green-800 text-green-200"
            break;
        case "orange":
            classParameters += " bg-orange-700 text-orange-50"
            break;
        case "light-orange":
            classParameters += " bg-orange-600 text-orange-100"
            break;
        case "yellow":
            classParameters += " bg-yellow-600 text-yellow-100"
            break;
        case "blue":
            classParameters += " bg-blue-700 text-blue-200"
            break;
    }

    return (<button className={classParameters} onClick={action}>{text}</button>)
}

export default CalendarBadge