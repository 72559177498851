import Card from "../organisms/Card";
import {useEffect, useState} from "react";
import sendRequest from "../../ApiManager/ApiManager";
import {DEFAULT_DATE_FORMAT} from "../../helper/DateHelper";
import {ReservationInterface} from "../../utils/Entities";
import useReport from "../../hooks/useReport";
import CardTitle from "../atoms/CardTitle";
import {ROUTE_RESERVATION_UPDATE} from "../../consts/Routes";
import SummaryTable from "../organisms/SummaryTable";
import moment from "moment/moment";

const Dashboard = () => {
  const date = moment()

  let startFormattedPeriodDate = date.format(DEFAULT_DATE_FORMAT)
  let endFormattedPeriodDate = date.format(DEFAULT_DATE_FORMAT)

  const [reservations, setReservations] = useState<ReservationInterface[]>([])

  const {reportDataCollection, initiateMapReportData} = useReport()

  useEffect(() => {
    sendRequest(
      'get',
      `/reservations?startDate=${startFormattedPeriodDate}&endDate=${endFormattedPeriodDate}`
    ).then((response) => {
      // mapCalendarReservedRooms(response.data)
      //
      // setLoading(false)

      setReservations(response.data)
    }).catch(() => {
      // setFetchError(true)
    })
  }, [])

  const handleClickOnReservation = (reservationId: number) => {
    window.open(ROUTE_RESERVATION_UPDATE.replace(":reservationId", reservationId.toString()), "_self")
  }

  useEffect(() => {
    initiateMapReportData(reservations)
  }, [reservations])

  return (
    <Card className={"pb-4"}>
      <CardTitle text={"Raport rezerwacji na " + date.format("LL")}/>
      <SummaryTable reportDataCollection={reportDataCollection} handleClickOnReservation={handleClickOnReservation}/>
    </Card>
  )
}

export default Dashboard