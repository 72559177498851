import React, {ReactElement} from "react";
import {TableTDInterface} from "../../atoms/table/TableTD";

export interface TableBodyRowInterface {
  children?: ReactElement<TableTDInterface> | ReactElement<TableTDInterface>[]
  className?: string
  border?: boolean
  hover?: boolean
  onClick?: React.MouseEventHandler
}

const TableBodyRow = ({children, className, border, hover, onClick}: TableBodyRowInterface) => {
  const borderClassName = border ? "border-b border-gray-700 last:border-none" : ""
  const hoverClassName = hover ? "hover:bg-gray-600 hover:text-gray-300" : ""

  return (
    <tr className={`${borderClassName} ${hoverClassName} text-center ${className}`} onClick={onClick}>
      {children}
    </tr>
  )
}

export default TableBodyRow