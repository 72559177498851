import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

interface CalendarFetchErrorInterface {
    loading: boolean
    fetchError: boolean
}

const CalendarFetchError = ({loading, fetchError}: CalendarFetchErrorInterface) => {
    return (<div
        className={(!loading && "hidden") + " absolute w-full h-full bg-gray-900/70 flex items-center justify-center"}>
        <div className={"flex flex-col"}>
            <FontAwesomeIcon icon={faSpinner} spin size={"5x"}/>
            {
                fetchError &&
                <span className={"mt-4 text-red-500 text-xl font-semibold"}>Błąd ładowania kalendarza</span>
            }
        </div>
    </div>)
}

export default CalendarFetchError