import Card from "../organisms/Card";
import Table from "../organisms/Table";
import TableHead from "../molecules/table/TableHead";
import TableTH from "../atoms/table/TableTH";
import TableBody from "../molecules/table/TableBody";
import TableBodyRow from "../molecules/table/TableBodyRow";
import TableTD from "../atoms/table/TableTD";
import Badge from "../atoms/Badge";
import Input from "../molecules/input/Input";
import {faCalendarDays, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import Select from "../molecules/input/Select";
import {useEffect, useState} from "react";
import sendRequest from "../../ApiManager/ApiManager";
import moment from "moment";
import {DEFAULT_DATE_FORMAT} from "../../helper/DateHelper";
import {ReservationInterface} from "../../utils/Entities";
import useReport, {ReportDataInterface} from "../../hooks/useReport";
import CardTitle from "../atoms/CardTitle";
import {ROUTE_RESERVATION_UPDATE} from "../../consts/Routes";
import SummaryTable from "../organisms/SummaryTable";

const Summary = () => {

  const date = moment()

  let startFormattedPeriodDate = ""
  let endFormattedPeriodDate = ""

  const [searchDate, setSearchDate] = useState<string>()
  const [searchValue, setSearchValue] = useState<string>("")
  const [filterValue, setFilterValue] = useState<string>("")
  const [reservations, setReservations] = useState<ReservationInterface[]>([])

  const {reportDataCollection, initiateMapReportData} = useReport()

  useEffect(() => {
    if (undefined === searchDate || 0 === searchDate.length) {
      startFormattedPeriodDate = date.add(-6, "months").format(DEFAULT_DATE_FORMAT)
      endFormattedPeriodDate = date.add(24, "months").format(DEFAULT_DATE_FORMAT)

      return;
    }

    const searchDateFromMoment = moment(searchDate);
    startFormattedPeriodDate = searchDateFromMoment.format(DEFAULT_DATE_FORMAT)
    endFormattedPeriodDate = searchDateFromMoment.format(DEFAULT_DATE_FORMAT)

  }, [searchDate])

  useEffect(() => {
    sendRequest(
      'get',
      `/reservations?startDate=${startFormattedPeriodDate}&endDate=${endFormattedPeriodDate}`
    ).then((response) => {
      // mapCalendarReservedRooms(response.data)
      //
      // setLoading(false)

      setReservations(response.data)
    }).catch(() => {
      // setFetchError(true)
    })
  }, [searchDate])

  const filterByReservedRoomStatus = (reportData: ReportDataInterface) => {
    return reportData.reservedRooms.some(reportReservedRoom => reportReservedRoom.statusSearch.includes(filterValue))
  }

  const filterReservations = (reservation: ReservationInterface) => {
    return reservation.reservation_guest.phone_number?.toLowerCase().includes(searchValue)
      || reservation.reservation_guest.name?.toLowerCase().includes(searchValue)
      || reservation.reservation_guest.email_address?.toLowerCase().includes(searchValue)
      || reservation.description?.toLowerCase().includes(searchValue)
      || reservation.id.toString().includes(searchValue)
  }

  const handleClickOnReservation = (reservationId: number) => {
    window.open(ROUTE_RESERVATION_UPDATE.replace(":reservationId", reservationId.toString()), "_self")
  }

  useEffect(() => {
    initiateMapReportData(reservations.filter(filterReservations), searchDate)
  }, [searchValue, searchDate, reservations])

  return (
    <Card className={"pb-4"}>
      <CardTitle text={"Wyszukiwarka rezerwacji"}/>
      <div className={"my-4 px-4"}>
        <form autoComplete={"off"} className={"flex space-x-2"}>
          <div className={"w-1/5"}>
            <Select
              label={"Filtruj według statusu zameldowania:"}
              inputName={"status"}
              onChange={setFilterValue}
              items={[
                {
                  text: "Wszystkie",
                  value: "",
                  selected: true
                },
                {
                  text: "W trakcie",
                  value: "during"
                },
                {
                  text: "Zameldowanie",
                  value: "check-in"
                },
                {
                  text: "Wymeldowanie",
                  value: "check-out"
                }
              ]}
            />
          </div>
          <div className={"w-1/5"}>
            <Input
              type={"date"}
              label={"Wybierz datę:"}
              inputName={"xasd"}
              icon={faCalendarDays}
              onChange={(event) => setSearchDate(event.target.value)}
            />
          </div>
          <div className={"w-3/5"}>
            <Input
              type={"text"}
              label={"Wyszukaj rezerwację po słowach kluczowych:"}
              inputName={"search"}
              placeholder={"Słowami kluczowymi mogą być ID rezerwacji, dane gości, notatki itd"}
              icon={faMagnifyingGlass}
              onChange={(event) => setSearchValue(event.target.value.toLowerCase())}
            />
          </div>
        </form>
      </div>
      <SummaryTable reportDataCollection={reportDataCollection.filter(filterByReservedRoomStatus)} handleClickOnReservation={handleClickOnReservation} />
    </Card>
  )
}

export default Summary