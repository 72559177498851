import React from 'react'

interface CardInterface {
    children?: React.JSX.Element | React.JSX.Element[]
    className?: string
}

const Card = ({children, className}: CardInterface) => {
    return (
        <div
            className={`bg-gray-800 border border-gray-700 rounded-lg shadow-sm text-gray-400 mb-6 ${className ?? ""}`}>
            {children}
        </div>
    );
}

export default Card