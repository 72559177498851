import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import InvoiceFormRow from "../../components/invoices/InvoiceFormRow";
import {fixedNullableValue, parseToFloat, parseToInt} from "../../components/Utils";
import axios from "axios";
import sendRequest from "../../ApiManager/ApiManager";
import {insertFormDataInterface, InvoiceCompanyInterface, InvoiceInterface} from "../../utils/Entities";
import {IssueCompanyType, LocaleType, PaymentMethodType, PaymentStatusType} from "../../utils/TypeUtils";
import {setProperty} from "dot-prop";
import {checkErrorHandler, clearErrorForField} from "../../helper/FormErrorHelper";

interface invoiceItemMutableInterface {
    name?: string,
    quantity?: number,
    price?: number
}

interface formDataErrors {
    issue_date?: string,
    issue_company?: string,
    invoice_type?: string,
    payment_method?: string,
    payment_status?: string,
    total_price?: string,
    price_to_pay?: string,
    company_name?: string,
    company_address?: {
        street: string,
        city: string,
        postal_code: string
    },
    company_nip?: string,
    description?: string,
    locale?: string
    days_to_pay?: string
}

interface invoiceMutableInterface {
    issue_date?: string,
    issue_company?: IssueCompanyType,
    invoice_type?: string,
    payment_method?: PaymentMethodType,
    payment_status?: PaymentStatusType,
    total_price?: number,
    price_to_pay?: number,
    company_name?: string,
    company_address?: {
        street: string,
        city: string,
        postal_code: string
    },
    company_nip?: string,
    description?: string,
    locale?: LocaleType
    days_to_pay?: number
}

interface invoiceItemInterface {
    itemId: number,
    data: invoiceItemMutableInterface,
    component: JSX.Element
}

interface invoiceItemsSettingsInterface {
    counter: number,
    items: invoiceItemInterface[]
}

interface manageRequestInterface {
    status: string,
    payload: any,
    errors: any,
    time: Date
}

interface fetchInvoicesInterface {
    payload: InvoiceInterface[]
}

interface initialStateInterface {
    formData: invoiceMutableInterface
    invoiceItemsSettings: invoiceItemsSettingsInterface
    formDataErrors: formDataErrors
    manageRequest: manageRequestInterface
    fetchInvoices: fetchInvoicesInterface,
    fetchInvoiceCompanies: InvoiceCompanyInterface[]
}

const invoiceItemsSettings: invoiceItemsSettingsInterface = {
    counter: 0,
    items: []
}

const initialState: initialStateInterface = {
    formData: {
        invoice_type: "sales_invoice",
        issue_company: 1,
        payment_method: "cash",
        payment_status: "unpaid",
        locale: "pl"
    },
    invoiceItemsSettings: invoiceItemsSettings,
    formDataErrors: {},
    manageRequest: {
        status: 'pending',
        payload: undefined,
        errors: undefined,
        time: new Date()
    },
    fetchInvoices: {
        payload: []
    },
    fetchInvoiceCompanies: []
}

const InvoiceManage = createSlice({
    name: 'invoiceManage',
    initialState: initialState,
    reducers: {
        clearStore(state) {
            state = initialState

            return state
        },
        addInvoiceRow(state, action: PayloadAction<{ isDeletable: boolean }>) {
            const counter = state.invoiceItemsSettings.counter

            const invoiceFormRow: invoiceItemInterface = {
                itemId: counter,
                component: <InvoiceFormRow id={counter} key={counter} isDeletable={action.payload.isDeletable}/>,
                data: {
                    name: undefined,
                    price: undefined,
                    quantity: 1
                }
            }
            state.invoiceItemsSettings.items = [
                ...state.invoiceItemsSettings.items,
                invoiceFormRow
            ]
            state.invoiceItemsSettings.counter = counter + 1;
        },
        addDataToInvoiceRow(state, action: PayloadAction<{ itemId: number, data: invoiceItemMutableInterface, }>) {
            const {itemId, data} = action.payload;

            const index = getIndexOfReservedRoomRow(state, itemId);
            const invoiceItem = state.invoiceItemsSettings.items[index]

            state.invoiceItemsSettings.items[index].data = Object.assign({}, invoiceItem.data, data)

            sumPricesFromInvoiceItems(state)
        },
        addDataToRequest(state, action: PayloadAction<insertFormDataInterface>) {
            const {field, value} = action.payload

            setProperty(state.formData, field, value)

            if (field.includes("locale")) {
                state.invoiceItemsSettings.items.forEach((item => {
                    item.data.name = undefined
                }))
            }

            if (field.includes("company_nip")) {
                findInvoiceCompany(state);
            }

            calculatePriceToPay(state)
            state.formDataErrors = clearErrorForField(state.formDataErrors, field)
        },
        addDataPriceToPayToRequest(state, action: PayloadAction<invoiceMutableInterface>) {
            state.formData = Object.assign({}, state.formData, action.payload)
        },
        deleteInvoiceRow(state, action: PayloadAction<number>) {
            state.invoiceItemsSettings.items = state.invoiceItemsSettings.items.filter(value => value.itemId !== action.payload)

            sumPricesFromInvoiceItems(state)
        },
    },
    extraReducers(builder) {
        builder
            .addCase(sendCreateInvoiceRequest.fulfilled, (state, action) => {
                state.manageRequest.status = 'successfully'
                state.manageRequest.payload = action.payload
            })
            .addCase(sendCreateInvoiceRequest.rejected, (state, action) => {
                state.manageRequest.status = 'rejected'
                state.manageRequest.errors = action.payload
                state.formDataErrors = checkErrorHandler(state.formDataErrors, state.manageRequest.errors)
            })
            .addCase(sendFetchInvoicesRequest.fulfilled, (state, action) => {
                state.fetchInvoices.payload = action.payload
            })
            .addCase(sendFetchCompanyInvoicesRequest.fulfilled, (state, action) => {
                state.fetchInvoiceCompanies = action.payload
            })
    }
})

export const sendCreateInvoiceRequest = createAsyncThunk('posts/createInvoice', async (bodyToRequest: initialStateInterface, thunkAPI) => {
    try {
        const response = await sendRequest('post', '/invoices', prepareDataToRequest(bodyToRequest));
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const sendFetchInvoicesRequest = createAsyncThunk('get/invoices', async (parameters, thunkAPI) => {
    try {
        const response = await sendRequest('get', '/invoices');
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const sendDeleteInvoiceRequest = createAsyncThunk('delete/invoice', async (invoiceId: number, thunkAPI) => {
    try {
        const response = await sendRequest('delete', '/invoices/' + invoiceId);
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const sendFetchCompanyInvoicesRequest = createAsyncThunk('get/invoiceCompanies', async (state, thunkAPI) => {
    try {
        const response = await sendRequest('get', '/invoices/companies');
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

const prepareDataToRequest = (state: initialStateInterface) => {
    const invoiceItems = [];

    for (let i = 0; i < state.invoiceItemsSettings.items.length; i++) {
        invoiceItems.push({
            name: state.invoiceItemsSettings.items[i].data.name,
            quantity: parseToInt(state.invoiceItemsSettings.items[i].data.quantity),
            price_per_item: parseToFloat(state.invoiceItemsSettings.items[i].data.price),
        })
    }

    const dataToRequest = state.formData
    const fixedDataToRequest = {
        issue_company: parseToInt(state.formData.issue_company),
        total_price: parseToFloat(state.formData.total_price),
        price_to_pay: parseToFloat(state.formData.price_to_pay),
        days_to_pay: parseToInt(state.formData.days_to_pay),
        description: fixedNullableValue(state.formData.description),
        invoice_items: invoiceItems
    }

    return Object.assign({}, dataToRequest, fixedDataToRequest)
}

const getIndexOfReservedRoomRow = (state: initialStateInterface, rowId: number): number => {
    const reservedRoomRowProxy = state.invoiceItemsSettings.items.find(value => value.itemId === rowId)

    if (undefined === reservedRoomRowProxy) {
        throw new Error("Not Found")
    }

    return state.invoiceItemsSettings.items.indexOf(reservedRoomRowProxy);
}

const sumPricesFromInvoiceItems = (state: initialStateInterface): initialStateInterface => {
    let totalPrice: number = 0;

    for (let i = 0; i < state.invoiceItemsSettings.items.length; i++) {
        const price = state.invoiceItemsSettings.items[i].data.price;
        const quantity = state.invoiceItemsSettings.items[i].data.quantity
        if (undefined !== price && undefined !== quantity) {
            const multiplePrice = price * quantity
            totalPrice += parseFloat(multiplePrice.toString());
        }
    }

    state.formData.total_price = parseFloat(totalPrice.toFixed(2));

    calculatePriceToPay(state)

    return state
}

const calculatePriceToPay = (state: initialStateInterface): initialStateInterface => {
    if ('unpaid' === state.formData.payment_status) {
        state.formData.price_to_pay = state.formData.total_price
    } else {
        state.formData.price_to_pay = 0
    }

    return state
}

const findInvoiceCompany = (state: initialStateInterface): initialStateInterface => {
    const invoiceCompany = state.fetchInvoiceCompanies.filter(value => value.nip === state.formData.company_nip)
    if (invoiceCompany.length > 0) {
        state.formData.company_name = invoiceCompany[0].name;
        state.formData.company_address = invoiceCompany[0].address;
    }

    return state;
}

export const manageInvoiceActions = InvoiceManage.actions;

export default InvoiceManage;