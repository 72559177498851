import Card from "../../organisms/Card";
import CardTitle from "../../atoms/CardTitle";
import Select from "../../molecules/input/Select";
import Input from "../../molecules/input/Input";
import {faCalendarDays, faDownload, faMagnifyingGlass, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Table from "../../organisms/Table";
import TableBody from "../../molecules/table/TableBody";
import TableBodyRow from "../../molecules/table/TableBodyRow";
import TableTD from "../../atoms/table/TableTD";
import TableHead from "../../molecules/table/TableHead";
import TableTH from "../../atoms/table/TableTH";
import {InvoiceCompanyInterface, InvoiceInterface} from "../../../utils/Entities";
import sendRequest from "../../../ApiManager/ApiManager";
import {formattedPriceView} from "../../../helper/PriceHelper";
import Badge from "../../atoms/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import moment, {Moment} from "moment";
import Modal from "../../modal/Modal";
import {useModal} from "../../../hooks/useModal";

const Invoices = () => {
  const [startDate, setStartDate] = useState<Moment>()
  const [endDate, setEndDate] = useState<Moment>()
  const [ownerFilter, setOwnerFilter] = useState<string>("0")
  const [modal, setModal] = useState<React.JSX.Element>()

  const [invoices, setInvoices] = useState<InvoiceInterface[]>([])

  useEffect(() => {
    sendRequest('get', 'invoices')
      .then((response) => setInvoices(response.data))
      .catch(() => {
        //do nothing
      })
  }, [])

  const handleDownloadInvoicePdf = (invoiceId: number) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const downloadUrl: string = `${apiUrl}/api/v1/invoices/${invoiceId}/download`

    window.open(downloadUrl, "_blank")
  }

  const handleDeleteInvoice = (invoiceId: number) => {
    sendRequest('delete', `/invoices/${invoiceId}`)
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        //something is broken
      })
  }

  const filterInvoices = (invoice: InvoiceInterface) => {
    const issueDate = moment(invoice.issue_date)
    return (invoice.issue_company === parseInt(ownerFilter) || parseInt(ownerFilter) === 0)
      && (undefined === startDate || issueDate >= startDate)
      && (undefined === endDate || issueDate <= endDate)
  }

  return <Card className={"pb-4"}>
    <CardTitle text={"Lista faktur"}/>
    <div className={"my-4 px-4"}>
      <form autoComplete={"off"} className={"flex space-x-2"}>
        <div className={"w-1/5"}>
          <Input
            type={"date"}
            label={"Wybierz początek okresu wyszukiwań:"}
            inputName={"start_date"}
            icon={faCalendarDays}
            onChange={(event) => {
              if (event.target.value.length === 0) {
                setStartDate(undefined)

                return
              }
              setStartDate(moment(event.target.value))
            }
            }
          />
        </div>
        <div className={"w-1/5"}>
          <Input
            type={"date"}
            label={"Wybierz koniec okresu wyszukiwań:"}
            inputName={"end_date"}
            icon={faCalendarDays}
            onChange={(event) => {
              if (event.target.value.length === 0) {
                setEndDate(undefined)

                return
              }
              setEndDate(moment(event.target.value))
            }}
          />
        </div>
        <div className={"w-3/5"}>
          <Select
            label={"Wyświetl faktury wystawione przez:"}
            inputName={"owner"}
            items={[
              {
                text: "Wszystkie",
                value: 0,
                selected: true
              },
              {
                text: "Bogdan",
                value: 1
              },
              {
                text: "Małgorzata",
                value: 2
              },
            ]}
            onChange={setOwnerFilter}
          />
        </div>
      </form>
    </div>
    <>{modal}</>
    <Table>
      <TableHead>
        <TableTH className={"w-1/12"}>ID Faktury</TableTH>
        <TableTH className={"w-1/12"}>Wystawiający</TableTH>
        <TableTH className={"w-3/12"}>Nazwa firmy</TableTH>
        <TableTH className={"w-2/12"}>Adres</TableTH>
        <TableTH className={"w-2/12"}>NIP</TableTH>
        <TableTH className={"w-2/12"}>Kwota brutto</TableTH>
        <TableTH className={"w-1/12"}>Akcje</TableTH>
      </TableHead>
      <TableBody>
        <>
          {
            invoices
              .filter(filterInvoices)
              .map(invoice => {
                return <TableBodyRow border hover key={Math.random()}>
                  <TableTD>
                    <Badge badgeType={"blue"} text={invoice.format}/>
                  </TableTD>
                  <TableTD>
                    <Badge badgeType={"yellow"} text={invoice.issue_company === 1 ? "Bogdan" : "Małgorzata"}/>
                  </TableTD>
                  <TableTD>{invoice.company_name}</TableTD>
                  <TableTD>
                    {invoice.company_address.street}, {invoice.company_address.postal_code} {invoice.company_address.city}
                  </TableTD>
                  <TableTD>{invoice.company_nip}</TableTD>
                  <TableTD>{formattedPriceView(invoice.total_price)}</TableTD>
                  <TableTD className={"space-x-2"}>
                    <button
                      className={"bg-blue-900 text-blue-100 py-1 px-2 rounded-full"}
                      onClick={() => handleDownloadInvoicePdf(invoice.id)}
                    >
                      <FontAwesomeIcon icon={faDownload}/>
                    </button>
                    <button
                      className={"bg-red-700 text-red-200 py-1 px-2 rounded-full"}
                      onClick={() => setModal(<Modal
                        message={`Czy chcesz usunąć fakturę ${invoice.format}`}
                        confirmAction={() => handleDeleteInvoice(invoice.id)}
                        key={Math.random()}/>)}
                    >
                      <FontAwesomeIcon icon={faTrash}/>
                    </button>
                  </TableTD>
                </TableBodyRow>
              })
          }
        </>
      </TableBody>
    </Table>
  </Card>
}

export default Invoices