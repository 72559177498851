import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fixedNullableValue, parseToFloat, parseToInt} from "../../components/Utils";
import axios from "axios";
import sendRequest from "../../ApiManager/ApiManager";
import {responseStatus} from "../../utils/TypeUtils";
import {checkErrorHandler, clearErrorForField} from "../../helper/FormErrorHelper";
import {insertFormDataInterface} from "../../utils/Entities";

interface roomMutableInterface {
    name?: string,
    single_beds?: number | string,
    double_beds?: number | string,
    price_per_day?: number | string,
    description?: string,
}

interface formDataErrors {
    name?: string,
    single_beds?: string
    double_beds?: string
    price_per_day?: string
    description?: string
}

interface initialStateInterface {
    formData: roomMutableInterface
    formDataErrors: formDataErrors
    requestData: {
        error: boolean
        status: responseStatus
        payload?: any
    }
}

interface updateRoomInterface {
    roomId: number,
    bodyToRequest: roomMutableInterface
}

const initialFormData: roomMutableInterface = {
    name: undefined,
    single_beds: undefined,
    double_beds: undefined,
    price_per_day: undefined,
    description: undefined
}

const initialState: initialStateInterface = {
    formData: initialFormData,
    formDataErrors: {},
    requestData: {
        error: false,
        status: 'pending',
        payload: null
    }
}

const roomManage = createSlice({
    name: 'RoomManage',
    initialState: initialState,
    reducers: {
        insertFormData(state, action: PayloadAction<insertFormDataInterface>) {
            state.formData = Object.assign({}, state.formData, {
                [action.payload.field]: action.payload.value
            })
            state.formDataErrors = clearErrorForField(state.formDataErrors, action.payload.field)
        },
        clearStore(state) {
            state = initialState

            return state
        },
    },
    extraReducers(builder) {
        builder
            .addCase(sendCreateRoomRequest.fulfilled, (state, action) => {
                state.requestData.status = 'successfully'
                state.requestData.error = false
                state.requestData.payload = action.payload
                state.formData = initialFormData
            })
            .addCase(sendCreateRoomRequest.rejected, (state, action) => {
                state.requestData.status = 'rejected'
                state.requestData.error = true
                state.requestData.payload = action.payload
                state.formDataErrors = checkErrorHandler(state.formDataErrors, state.requestData.payload)
            })
            .addCase(sendUpdateRoomRequest.fulfilled, (state, action) => {
                state.requestData.status = 'successfully'
                state.requestData.error = false
                state.requestData.payload = action.payload
            })
            .addCase(sendUpdateRoomRequest.rejected, (state, action) => {
                state.requestData.status = 'rejected'
                state.requestData.error = true
                state.requestData.payload = action.payload
                state.formDataErrors = checkErrorHandler(state.formDataErrors, state.requestData.payload)
            })
            .addCase(sendFetchRoomRequest.fulfilled, (state, action) => {
                state.requestData.status = 'pending'
                state.requestData.error = false
                state.requestData.payload = action.payload
                state.formData = action.payload
            })
            .addCase(sendFetchRoomRequest.rejected, (state, action) => {
                state.requestData.status = 'rejected'
                state.requestData.error = true
                state.requestData.payload = action.payload
            })
    }
})

export const sendCreateRoomRequest = createAsyncThunk('post/createRoom', async (createRoom: roomMutableInterface, thunkAPI) => {
    try {
        const response = await sendRequest(
            'post',
            '/rooms',
            prepareDataToRequest(createRoom)
        )
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const sendDeleteRoomRequest = createAsyncThunk('delete/deleteRoom', async (roomId: number, thunkAPI) => {
    try {
        const response = await sendRequest(
            'delete',
            '/rooms/' + roomId
        )
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const sendUpdateRoomRequest = createAsyncThunk('post/updateRoom', async (updateRoom: updateRoomInterface, thunkAPI) => {
    try {
        const response = await sendRequest(
            'post',
            '/rooms/' + updateRoom.roomId,
            prepareDataToRequest(updateRoom.bodyToRequest)
        )
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const sendFetchRoomRequest = createAsyncThunk('get/getRoom', async (roomId: number, thunkAPI) => {
    try {
        const response = await sendRequest(
            'get',
            '/rooms/' + roomId
        );
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

const prepareDataToRequest = (bodyToRequest: roomMutableInterface) => {
    return {
        name: bodyToRequest.name,
        single_beds: parseToInt(bodyToRequest.single_beds),
        double_beds: parseToInt(bodyToRequest.double_beds),
        price_per_day: parseToFloat(bodyToRequest.price_per_day),
        description: fixedNullableValue(bodyToRequest.description)
    };
}

export const manageRoomActions = roomManage.actions;
export default roomManage;