import useReservationCalendar from "../../hooks/useReservationCalendar";
import CalendarMonthAndYear from "../atoms/calendar/CalendarMonthAndYear";
import CalendarNavigationButton from "../molecules/calendar/CalendarNavigationButton";
import CalendarDay from "../molecules/calendar/CalendarDay";
import {useEffect, useState} from "react";
import sendRequest from "../../ApiManager/ApiManager";
import useCalendarReservedRoom from "../../hooks/useCalendarReservedRoom";
import CalendarFetchError from "../molecules/calendar/CalendarFetchError";
import CalendarDrawer from "../organisms/CalendarDrawer";
import useCalendarDrawer from "../../hooks/useCalendarDrawer";
import {dayName} from "../../helper/CalendarHelper";

const Calendar = () => {
  const {
    selectedDate,
    calendarDays,
    increaseMonthHandle,
    decreaseMonthHandle,
    currentMonthHandle,
    startFormattedPeriodDate,
    endFormattedPeriodDate
  } = useReservationCalendar()
  const {
    calendarReservedRooms,
    mapCalendarReservedRooms
  } = useCalendarReservedRoom()
  const {isVisible, reservation, handleCloseAction, handleGetReservationAction} = useCalendarDrawer()

  const [fetchError, setFetchError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setFetchError(false)
  }, [startFormattedPeriodDate, endFormattedPeriodDate])

  useEffect(() => {
    sendRequest(
      'get',
      `/reservations?startDate=${startFormattedPeriodDate}&endDate=${endFormattedPeriodDate}`
    ).then((response) => {
      mapCalendarReservedRooms(response.data)

      setLoading(false)
    }).catch(() => {
      setFetchError(true)
    })
  }, [startFormattedPeriodDate, endFormattedPeriodDate])

  return (
    <>
      <CalendarDrawer
        isView={isVisible}
        reservation={reservation}
        closeHandleAction={handleCloseAction}
      />
      <div className={"bg-gray-800 border border-gray-700 rounded-lg shadow-sm text-gray-400 mb-6"}>
        <div className={"w-full h-16 flex items-center justify-between px-4"}>
          <CalendarMonthAndYear
            month={selectedDate.format("MMMM")}
            year={selectedDate.year()}
          />
          <div>
            <CalendarNavigationButton direction={"left"} action={decreaseMonthHandle}/>
            <CalendarNavigationButton direction={"now"} action={currentMonthHandle}/>
            <CalendarNavigationButton direction={"right"} action={increaseMonthHandle}/>
          </div>
        </div>
        <div className={"grid grid-cols-7 border-t border-gray-700 relative"} id={"calendar"}>
          <CalendarFetchError loading={loading} fetchError={fetchError}/>
          {
            calendarDays.map((value, index) => {
              return <CalendarDay
                isSelectedMonth={value.isSelectedMonth}
                isNextDayInSelectedMonth={value.isNextDayInSelectedMonth}
                isToday={value.isToday}
                isSunday={value.isSunday}
                isBottomBorder={value.isBottomBorder}
                dateNumber={value.dateNumber}
                formattedDate={value.formattedDate}
                calendarReservedRooms={calendarReservedRooms[value.formattedDate] ?? []}
                openDrawerHandleAction={handleGetReservationAction}
                dayName={dayName[index] ?? undefined}
                key={value.formattedDate}
              />
            })
          }
        </div>
      </div>
    </>
  )
}

export default Calendar