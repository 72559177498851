import React, {useEffect} from "react";
import Heading from "../utils/Heading";
import Input from "../form/Input";
import Select from "../form/Select";
import FormButton from "../form/FormButton";
import Textarea from "../form/Textarea";
import Card from "../card/Card";
import FormRow from "../form/FormRow";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    manageInvoiceActions,
    sendCreateInvoiceRequest,
    sendFetchCompanyInvoicesRequest
} from "../../redux/features/InvoiceManage";
import {getResponseErrorMessage} from "../utils/ErrorAlert";
import {messages} from "../../Settings";
import {useAlert} from "../../hooks/useAlert";

function InvoiceForm() {
    const invoiceManage = useAppSelector(state => state.invoiceManage)
    const dispatch = useAppDispatch();
    const {alerts, addAlert} = useAlert()

    const handleAddInvoiceRow = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        dispatch(manageInvoiceActions.addInvoiceRow({isDeletable: true}))
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target

        dispatch(manageInvoiceActions.addDataToRequest({
            field: name,
            value: value
        }))
    }
    const handleInputForPriceToPay = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name

        const dataToDispatch = {
            [name]: event.target.value
        }

        dispatch(manageInvoiceActions.addDataPriceToPayToRequest(dataToDispatch))
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()

        dispatch(sendCreateInvoiceRequest(invoiceManage))
    }

    useEffect(() => {
        dispatch(manageInvoiceActions.clearStore())
        dispatch(manageInvoiceActions.addInvoiceRow({isDeletable: false}))
        dispatch(sendFetchCompanyInvoicesRequest())
    }, [dispatch])


    useEffect(() => {
        if ('successfully' === invoiceManage.manageRequest.status) {
            addAlert(
                "success",
                messages.invoices.create
            )
        } else if ('rejected' === invoiceManage.manageRequest.status) {
            addAlert(
                "danger",
                getResponseErrorMessage(invoiceManage.manageRequest.errors)
            )
        }
    }, [invoiceManage.manageRequest.errors, invoiceManage.manageRequest.status, invoiceManage.manageRequest.payload])

    return (
        <Card>
            <div className={"w-full"}>
                <Heading size={3} text={"Generowanie faktur"}/>
                {alerts}
                <form className={"w-full flex flex-col py-4"} onSubmit={handleSubmit}>
                    <FormRow>
                        <div className={"w-1/5"}>
                            <Input
                                type={"date"}
                                name={"issue_date"}
                                title={"Data wystawienia faktury"}
                                defaultValue={invoiceManage.formData.issue_date}
                                errorMessage={invoiceManage.formDataErrors.issue_date}
                                isRequired={true}
                                onChange={handleInput}
                            />
                        </div>
                        <div className={"w-1/5"}>
                            <Select
                                name={"invoice_type"}
                                title={"Wybierz rodzaj faktury"}
                                isRequired={true}
                                onChange={handleInput}
                                value={invoiceManage.formData.invoice_type}
                                errorMessage={invoiceManage.formDataErrors.invoice_type}
                                options={
                                    [
                                        {
                                            value: "sales_invoice",
                                            text: "Faktura VAT"
                                        },
                                        {
                                            value: "prepayment_invoice",
                                            text: "Faktura zaliczkowa"
                                        }
                                    ]
                                }/>
                        </div>
                        <div className={"w-1/5"}>
                            <Select
                                name={"issue_company"}
                                title={"Firma, która wystawia fakturę"}
                                isRequired={true}
                                onChange={handleInput}
                                value={invoiceManage.formData.issue_company}
                                errorMessage={invoiceManage.formDataErrors.issue_company}
                                options={
                                    [
                                        {
                                            value: 1,
                                            text: "Bogdan"
                                        },
                                        {
                                            value: 2,
                                            text: "Małgorzata"
                                        }
                                    ]
                                }/>
                        </div>
                        <div className={"w-1/5"}>
                            <Select
                                name={"locale"}
                                title={"Język tłumaczenia faktury"}
                                isRequired={true}
                                onChange={handleInput}
                                value={invoiceManage.formData.locale}
                                errorMessage={invoiceManage.formDataErrors.locale}
                                options={
                                    [
                                        {
                                            value: "pl",
                                            text: "Polski"
                                        },
                                        {
                                            value: "en",
                                            text: "Angielski"
                                        }
                                    ]
                                }/>
                        </div>
                        <div className={"w-1/5"}>
                            <Input
                                type={"number"}
                                name={"days_to_pay"}
                                title={"Liczba dni na opłatę faktury"}
                                defaultValue={invoiceManage.formData.days_to_pay}
                                errorMessage={invoiceManage.formDataErrors.days_to_pay}
                                placeholder={"14"}
                                isRequired={true}
                                min={1}
                                onChange={handleInput}
                            />
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className={"w-3/12"}>
                            <Input
                                type={"text"}
                                name={"company_name"}
                                title={"Nazwa firmy"}
                                placeholder={"Wprowadź nazwę firmy, np. MyCraft S.A"}
                                defaultValue={invoiceManage.formData.company_name}
                                errorMessage={invoiceManage.formDataErrors.company_name}
                                isRequired={true}
                                onChange={handleInput}
                            />
                        </div>
                        <div className={"w-3/12"}>
                            <Input
                                type={"text"}
                                name={"company_nip"}
                                title={"NIP firmy"}
                                placeholder={"Może być również z prefixem unijnym"}
                                defaultValue={invoiceManage.formData.company_nip}
                                errorMessage={invoiceManage.formDataErrors.company_nip}
                                isRequired={true}
                                onChange={handleInput}
                            />
                        </div>
                        <div className={"w-2/12"}>
                            <Input
                                type={"text"}
                                name={"company_address.street"}
                                title={"Adres firmy - ulica"}
                                placeholder={"Starowiślna 34a"}
                                defaultValue={invoiceManage.formData.company_address?.street}
                                errorMessage={invoiceManage.formDataErrors.company_address?.street}
                                isRequired={true}
                                onChange={handleInput}
                            />
                        </div>
                        <div className={"w-2/12"}>
                            <Input
                                type={"text"}
                                name={"company_address.city"}
                                title={"Adres firmy - miasto"}
                                placeholder={"Kraków"}
                                defaultValue={invoiceManage.formData.company_address?.city}
                                errorMessage={invoiceManage.formDataErrors.company_address?.city}
                                isRequired={true}
                                onChange={handleInput}
                            />
                        </div>
                        <div className={"w-2/12"}>
                            <Input
                                type={"text"}
                                name={"company_address.postal_code"}
                                title={"Adres firmy - kod pocztowy"}
                                defaultValue={invoiceManage.formData.company_address?.postal_code}
                                errorMessage={invoiceManage.formDataErrors.company_address?.postal_code}
                                placeholder={"30-348"}
                                isRequired={true}
                                onChange={handleInput}
                            />
                        </div>
                    </FormRow>
                    <div className={"my-5"}>
                        {
                            invoiceManage.invoiceItemsSettings.items.map(value => {
                                return value.component;
                            })
                        }
                    </div>
                    <FormRow>
                        <FormButton
                            type={"info"}
                            text={"Dodaj kolejną pozycję na fakturze"}
                            buttonType={"button"}
                            handleClick={handleAddInvoiceRow}
                        />
                    </FormRow>
                    <FormRow>
                        <div className={"w-1/4"}>
                            <Select
                                name={"payment_method"}
                                title={"Metoda płatności"}
                                isRequired={true}
                                value={invoiceManage.formData.payment_method}
                                errorMessage={invoiceManage.formDataErrors.payment_method}
                                onChange={handleInput}
                                options={
                                    [
                                        {
                                            value: "cash",
                                            text: "Gotówka"
                                        },
                                        {
                                            value: "transfer",
                                            text: "Przelew"
                                        },
                                        {
                                            value: "booking",
                                            text: "Booking.com"
                                        }
                                    ]
                                }
                            />
                        </div>
                        <div className={"w-1/4"}>
                            <Select
                                name={"payment_status"}
                                title={"Status płatności"}
                                isRequired={true}
                                value={invoiceManage.formData.payment_status}
                                errorMessage={invoiceManage.formDataErrors.payment_status}
                                onChange={handleInput}
                                options={
                                    [
                                        {
                                            value: "unpaid",
                                            text: "Nieopłacona"
                                        },
                                        {
                                            value: "paid",
                                            text: "Opłacona"
                                        }
                                    ]
                                }
                            />
                        </div>
                        <div className={"w-1/4"}>
                            <Input
                                type={"number"}
                                name={"total_price"}
                                title={"Całkowita kwota brutto"}
                                placeholder={"250.00"}
                                isRequired={true}
                                disabled={true}
                                defaultValue={invoiceManage.formData.total_price}
                                errorMessage={invoiceManage.formDataErrors.total_price}
                                step={0.01}
                                onChange={handleInput}
                            />
                        </div>
                        <div className={"w-1/4"}>
                            <Input
                                type={"number"}
                                name={"price_to_pay"}
                                title={"Kwota do zapłaty"}
                                placeholder={"250.00"}
                                isRequired={true}
                                defaultValue={invoiceManage.formData.price_to_pay}
                                errorMessage={invoiceManage.formDataErrors.price_to_pay}
                                step={0.01}
                                onChange={handleInputForPriceToPay}
                            />
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className={"w-full"}>
                            <Textarea
                                name={"description"}
                                title={"Dodatkowe informacje"}
                                placeholder={"Te informacje pojawią się na fakturze, jeżeli zostaną wpisane"}
                                defaultValue={invoiceManage.formData.description}
                                errorMessage={invoiceManage.formDataErrors.description}
                                rows={5}
                                onChange={handleInput}
                            />
                        </div>
                    </FormRow>
                    <div className={"w-full flex space-x-2 justify-end"}>
                        <div className={"w-1/4"}>
                            <FormButton
                                type={"success"}
                                text={"Wygeneruj fakturę"}
                                buttonType={"submit"}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Card>
    );
}

export default InvoiceForm;