import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface NavigateIconInterface {
    icon?: IconProp
}

const NavigateIcon = ({icon}: NavigateIconInterface) => {
    if (undefined === icon) {
        return null;
    }
    return <FontAwesomeIcon
        icon={icon}
        className={"w-5 h-5 transition duration-75 group-hover:text-white"}/>
}

export default NavigateIcon