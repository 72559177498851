import React, {useEffect} from "react";
import {authorizationUser, manageAuthActions} from "../../redux/features/AuthorizationManage";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {DASHBOARD} from "../../Settings";
import {getResponseErrorMessage} from "../../components/utils/ErrorAlert";
import {useNavigate} from "react-router-dom";
import {useAlert} from "../../hooks/useAlert";
import Input from "../../components/form/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons";

function Login() {
    const authorizationManage = useAppSelector((state) => state.authorizationManage)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {alerts, addAlert} = useAlert()

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();

        dispatch(authorizationUser(authorizationManage))
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name

        const dataToDispatch = {
            [name]: event.target.value
        }

        dispatch(manageAuthActions.insertDataToBody(dataToDispatch))
    }


    useEffect(() => {
        if ('successfully' === authorizationManage.manageRequest.status) {
            navigate(DASHBOARD)
        } else if ('rejected' === authorizationManage.manageRequest.status) {
            addAlert(
                "danger",
                getResponseErrorMessage(authorizationManage.manageRequest.errors),
                10
            )
        }
    }, [authorizationManage.manageRequest.status, authorizationManage.manageRequest.errors, authorizationManage.manageRequest.payload])

    return (
        <div className={"min-h-screen flex w-full flex-col items-center justify-center bg-gray-900 font-inter"}>
            <div
                className={"w-full md:w-3/5 lg:w-5/6 min-h-screen flex flex-col items-center justify-center lg:flex-row"}>
                <div
                    className={"w-full xl:w-3/4 2xl:w-1/2 flex flex-col lg:flex-row min-h-screen md:h-auto items-center justify-center"}>
                    <div className={"w-5/6 lg:w-full xl:w-3/5 border bg-gray-800 border-gray-700 px-6 pt-7 pb-8"}>
                        <div
                            className={"w-full flex flex-col text-center font-extrabold text-transparent bg-clip-text bg-gradient-to-r to-orange-600 from-yellow-400"}>
                            <h1 className={"text-9xl"}>VR</h1>
                            <h2 className={"text-6xl"}>System</h2>
                        </div>
                        <form className="space-y-4 mt-8 mb-4 md:space-y-6">
                            {alerts}
                            <Input
                                type={"text"}
                                name={"username"}
                                title={"Adres e-mail"}
                                placeholder={"kontakt@vrsystem.pl"}
                                onChange={handleInputChange}
                                isRequired={true}
                            />
                            <Input
                                type={"password"}
                                name={"password"}
                                title={"Hasło"}
                                placeholder={"••••••••••••••"}
                                onChange={handleInputChange}
                                isRequired={true}
                            />

                            <button
                                onClick={handleSubmit}
                                type={"submit"}
                                className={"bg-gradient-to-r to-orange-600 font-semibold from-yellow-500 hover:to-orange-700 hover:from-yellow-600 w-full px-5 py-2.5 rounded-lg text-white text-sm hover:text-gray-200 "}>
                                Zaloguj
                                <span className={"ml-2"}>
                                    <FontAwesomeIcon icon={faRightToBracket}/>
                                </span>
                            </button>
                        </form>
                        <p className="text-sm font-light text-gray-400 text-center">
                            W przypadku problemów z logowaniem skontaktuj się z administratorem zarządzającym systemem.
                        </p>
                    </div>
                </div>
                <div
                    className={"w-full py-8 md:pt-0 md:pt-8 xl:w-1/2 xl:min-h-screen flex items-center justify-center"}>
                    <img src={"/villarenoma_logo.png"} className={"w-1/2 lg:w-3/4 2xl:w-3/5"}/>
                </div>
            </div>
        </div>
    );
}

export default Login;