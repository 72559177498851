interface CalendarDayNumberInterface {
    text: string | number
    isSelectedMonth: boolean
    isToday: boolean
}

const CalendarDayNumber = ({text, isSelectedMonth, isToday}: CalendarDayNumberInterface) => {
    let classParameters = "text-gray-300"

    if (isSelectedMonth) {
        classParameters = "text-gray-400"
    }
    if (isToday) {
        classParameters = "text-white bg-cyan-700 p-1.5 rounded-full"
    }

    return <span className={"text-sm " + classParameters}>{text}</span>
}

export default CalendarDayNumber