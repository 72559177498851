import {alertType, PaymentMethodType, PaymentStatusType, ReservationSourceType} from "./TypeUtils";

export const getAlertColorConfiguration = (type: alertType) => {
  switch (type) {
    case "danger":
      return "bg-red-200 text-red-900"

    case "info":
      return "bg-blue-200 text-blue-800"

    case "success":
      return "bg-green-200 text-green-900"

    case "warning":
      return "bg-yellow-200 text-yellow-900"

    case "none":
      return ""
  }
}

export const getTranslatablePaymentMethod = (paymentMethod?: PaymentMethodType, upperFirstLetter?: boolean) => {
  switch (paymentMethod) {
    case "cash":
      return (upperFirstLetter ? "G" : "g") + "otówka"

    case "booking":
      return (upperFirstLetter ? "B" : "b") + "ooking.com"

    case "transfer":
      return (upperFirstLetter ? "P" : "p") + "rzelew"
  }
}

export const getTranslatablePaymentStatus = (paymentStatus?: PaymentStatusType, upperFirstLetter?: boolean): string => {
  switch (paymentStatus) {
    case "unpaid":
      return (upperFirstLetter ? "N" : "n") + "ieopłacone"
    case "paid":
      return (upperFirstLetter ? "O" : "o") + "płacone"
    case "prepaid":
      return (upperFirstLetter ? "W" : "w") + "płacona zaliczka"
  }

  return "Nie określono"
}

export const getTranslatableReservationSource = (reservationSource: ReservationSourceType, upperFirstLetter?: boolean) => {
  switch (reservationSource) {
    case "booking":
      return (upperFirstLetter ? "B" : "b") + "ooking.com"
    case "email":
      return (upperFirstLetter ? "E" : "e") + "mail"
    case "facebook":
      return (upperFirstLetter ? "F" : "f") + "acebook"
    case "phone":
      return (upperFirstLetter ? "T" : "t") + "elefon"
  }
}

export const getPaymentStatusColor = (paymentStatus: PaymentStatusType) => {
  switch (paymentStatus) {
    case "paid": {
      return "success"
    }
    case "unpaid": {
      return "danger"
    }
    case "prepaid": {
      return "warning"
    }
  }
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  phoneNumber.replace(/[^\d+]/g, "")

  if (phoneNumber.length === 12) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)}  ${phoneNumber.slice(6, 9)}  ${phoneNumber.slice(9, 13)}`;
  } else if (phoneNumber.length === 9) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)}  ${phoneNumber.slice(6, 9)}`;
  }

  return phoneNumber
}
