import React from "react";

interface helpLabelProps {
    name: string,
    title: string,
    required?: boolean
}

function HelpLabel(helpLabelProps: helpLabelProps) {
    const requiredElement = <span className={"text-red-600"}>*</span>
    return (
        <label htmlFor={helpLabelProps.name} className="block mb-2 text-sm font-medium text-gray-300">
            {helpLabelProps.title}
            {helpLabelProps.required ? requiredElement : null}
        </label>
    );
}

export default HelpLabel