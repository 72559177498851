import React from "react";

interface TableInterface {
  children?: React.JSX.Element | React.JSX.Element[]
}

const Table = ({children}: TableInterface) => {
  return (<table className={"w-full table-fixed"}>{children}</table>)
}

export default Table