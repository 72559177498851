import React from "react";

type headingRangeType = 1 | 2 | 3 | 4 | 5 | 6;

interface HeadingProps {
    size: headingRangeType,
    text: string,
    border?: boolean
}

function InternalHeading(headingProps: HeadingProps) {
    const border = headingProps.border ? "border-b border-gray-500" : ""
    const commonClasses = " text-gray-300 pt-1 pb-2 font-bold mb-4 " + border
    let headingElement;
    switch (headingProps.size) {
        case 1: {
            headingElement = <h1 className={"text-5xl" + commonClasses}>{headingProps.text}</h1>;
            break;
        }
        case 2: {
            headingElement = <h2 className={"text-4xl" + commonClasses}>{headingProps.text}</h2>;
            break;
        }
        case 3: {
            headingElement = <h3 className={"text-3xl" + commonClasses}>{headingProps.text}</h3>;
            break;
        }
        case 4: {
            headingElement = <h4 className={"text-2xl" + commonClasses}>{headingProps.text}</h4>;
            break;
        }
        case 5: {
            headingElement = <h5 className={"text-xl" + commonClasses}>{headingProps.text}</h5>;
            break;
        }
        case 6: {
            headingElement = <h6 className={"text-lg" + commonClasses}>{headingProps.text}</h6>;
            break;
        }
    }
    return (headingElement);
}

function Heading(headingProps: HeadingProps) {
    return <InternalHeading size={headingProps.size} text={headingProps.text} border={headingProps.border ?? true}/>
}

export default Heading;