interface CalendarDayNameInterface {
    text: string
    isSelectedMonth: boolean
}

const CalendarDayName = ({text, isSelectedMonth}: CalendarDayNameInterface) => {
    let classParameters = isSelectedMonth ? "text-gray-400" : "text-gray-300"

    return <span className={"font-bold " + classParameters}>{text}</span>
}

export default CalendarDayName