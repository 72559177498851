import moment from "moment";
import {useState} from "react";

interface CalendarDayInterface {
    isSelectedMonth: boolean
    isNextDayInSelectedMonth: boolean
    isToday: boolean
    isSunday: boolean
    isBottomBorder: boolean
    dayName?: string
    dateNumber: string | number
    formattedDate: string
}

function useReservationCalendar() {
    const currentDate = moment()

    const [selectedDate, setSelectedDate] = useState(currentDate)
    let calendarDays: CalendarDayInterface[] = []

    const decreaseMonthHandle = () => {
        const date = moment(selectedDate).add(-1, "month")
        setSelectedDate(date)
    }
    const increaseMonthHandle = () => {
        const date = moment(selectedDate).add(1, "month")
        setSelectedDate(date)
    }
    const currentMonthHandle = () => {
        setSelectedDate(currentDate)
    }

    const date = moment(selectedDate).add(-1, "month")
    date.endOf('month').startOf("week")

    const startFormattedPeriodDate = date.format("YYYY-MM-DD")
    const endFormattedPeriodDate = moment(selectedDate)
        .add(1, "month")
        .startOf('month')
        .add(1, 'week')
        .endOf('week')
        .format("YYYY-MM-DD")

    for (let i = 0; i < 42; i++) {
        const isSelectedMonth = selectedDate.month() === date.month()
        const isToday = isSelectedMonth && currentDate.format("YYYY-MM-DD") === date.format("YYYY-MM-DD")

        calendarDays.push({
            isSelectedMonth: isSelectedMonth,
            isNextDayInSelectedMonth: false,
            isToday: isToday,
            isSunday: 6 === date.weekday(),
            isBottomBorder: i < 35,
            dateNumber: date.format("DD"),
            formattedDate: date.format("YYYY-MM-DD")
        })

        date.add(1, "day")
    }

    return {
        decreaseMonthHandle,
        increaseMonthHandle,
        currentMonthHandle,
        calendarDays,
        selectedDate,
        startFormattedPeriodDate,
        endFormattedPeriodDate
    }
}

export default useReservationCalendar