import CalendarBadge, {colorType} from "../../atoms/calendar/CalendarBadge";
import {FontawesomeObject, IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface CalendarDrawerReservationLabelInterface {
    text: string
    badgeColor?: colorType
    badgeText?: string
    badgePosition?: "left" | "right"
}

const CalendarDrawerReservationLabel = (
    {
        text,
        badgeText,
        badgeColor,
        badgePosition
    }: CalendarDrawerReservationLabelInterface) => {
    const position = "left" === badgePosition ? "order-1" : ""
    return (
        <div className={"flex items-center justify-between py-4 border-b border-gray-700 last:border-none"}>
            <span className={`text-gray-400 text-sm text-justify ${position}`}>{text}</span>
            {
                (badgeText && badgePosition && badgeColor) && <CalendarBadge color={badgeColor} text={badgeText}/>
            }
        </div>
    )
}

export default CalendarDrawerReservationLabel