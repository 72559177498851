import React, {useEffect, useState} from "react";
import HelpLabel from "./HelpLabel";
import ErrorLabel from "./ErrorLabel";

type inputType = 'text' | 'email' | 'date' | 'number' | 'password';

interface inputInterface {
    type: inputType;
    name: string;
    title: string;
    placeholder?: string;
    isRequired?: boolean;
    defaultValue?: string | number | undefined;
    errorMessage?: string
    min?: string | number | undefined;
    max?: string | number | undefined;
    step?: number | undefined;
    disabled?: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onClick?: React.MouseEventHandler<HTMLInputElement>
}

function Input(input: inputInterface) {
    const disabledCursor = input.disabled && "cursor-not-allowed "
    const errorRing = input.errorMessage && "ring-red-600 ring-2 "

    const additionClasses = disabledCursor ?? "" + errorRing ?? ""

    const [defaultValue, setDefaultValue] = useState<string | number | undefined>()

    useEffect(() => {
        setDefaultValue(input.defaultValue)
    }, [input.defaultValue])

    return (
        <div>
            <HelpLabel name={input.name} title={input.title} required={input.isRequired}/>
            <input
                id={input.name}
                name={input.name}
                type={input.type}
                placeholder={input.placeholder}
                required={input.isRequired}
                defaultValue={defaultValue}
                min={input.min}
                max={input.max}
                disabled={input.disabled}
                onChange={input.onChange}
                onClick={input.onClick}
                step={input.step}
                className={additionClasses + "border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-none placeholder-gray-400 text-gray-100 focus:ring-2 focus:ring-orange-400 outline-none "}
            />
            <ErrorLabel errorMessage={input.errorMessage}/>
        </div>
    );
}

export default Input;