export type badgeType = "default" | "light-red" | "red" | "green" | "yellow" | "light-orange" | "orange" | "blue"

interface BadgeInterface {
    badgeType: badgeType
    text: string
}

const Badge = ({badgeType, text}: BadgeInterface) => {
    let classParameters: string = "py-0.5 px-2.5 rounded text-xs font-medium"

    switch (badgeType) {
        case "light-red":
            classParameters += " bg-red-400 text-red-50"
            break;
        case "red":
            classParameters += " bg-red-700 text-red-200"
            break;
        case "green":
            classParameters += " bg-green-800 text-green-200"
            break;
        case "orange":
            classParameters += " bg-orange-700 text-orange-50"
            break;
        case "light-orange":
            classParameters += " bg-orange-600 text-orange-100"
            break;
        case "yellow":
            classParameters += " bg-yellow-600 text-yellow-100"
            break;
        case "blue":
            classParameters += " bg-blue-700 text-blue-200"
            break;
        default:
            classParameters += " bg-blue-900 text-blue-200"
            break;
    }

    return (<span className={classParameters}>{text}</span>)
}

export default Badge