import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import RoomCreate from "./pages/rooms/RoomCreate";
import RoomList from "./pages/rooms/RoomList";
import RoomUpdate from "./pages/rooms/RoomUpdate";
import InvoiceCreate from "./pages/invoices/InvoiceCreate";
import Logs from "./components/pages/Logs";
import Login from "./pages/login/Login";
import store from "./redux/store";
import {Provider} from 'react-redux';
import ReservationCreate from "./pages/reservations/ReservationCreate";
import ReservationUpdate from "./pages/reservations/ReservationUpdate";
import Logout from "./pages/logout/Logout";
import Calendar from "./components/pages/Calendar";
import {ROUTE_DASHBOARD} from "./consts/Routes";
import Summary from "./components/pages/Summary";
import Dashboard from "./components/pages/Dashboard";
import Invoices from "./components/pages/invoice/Invoices";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path={ROUTE_DASHBOARD} element={<App/>}>
                    <Route path={""} element={<Dashboard/>}/>
                    <Route path={"rooms"}>
                        <Route path={"create"} element={<RoomCreate/>}/>
                        <Route path={"list"} element={<RoomList/>}/>
                        <Route path={"update/:roomId"} element={<RoomUpdate/>}/>
                    </Route>
                    <Route path={"reservations"}>
                        <Route path={"create"} element={<ReservationCreate/>}/>
                        <Route path={"update/:reservationId"} element={<ReservationUpdate/>}/>
                    </Route>
                    <Route path={"reports"} element={<Summary/>}/>
                    <Route path={"invoices"}>
                        <Route path={"create"} element={<InvoiceCreate/>}/>
                        <Route path={"list"} element={<Invoices/>}/>
                    </Route>
                    <Route path={"calendar"} element={<Calendar/>}/>
                    <Route path={"users"}/>
                    <Route path={"logs"} element={<Logs/>}/>
                    <Route path={"logout"} element={<Logout/>}/>
                </Route>
                <Route path={"*"} element={<Login/>}/>
            </Routes>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
