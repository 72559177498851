import CalendarDrawerTitle from "../../atoms/calendar/CalendarDrawerTitle";
import {CalendarDrawerReservationLabelInterface} from "./CalendarDrawerReservationLabel";
import {ReactElement} from "react";
import {CalendarDrawerReservationGuestLabelInterface} from "./CalendarDrawerReservationGuestLabel";

interface CalendarDrawerSectionInterface {
    title: string
    children?: ReactElement<CalendarDrawerReservationLabelInterface>
        | ReactElement<CalendarDrawerReservationLabelInterface>[]
        | ReactElement<CalendarDrawerReservationGuestLabelInterface>
        | ReactElement<CalendarDrawerReservationGuestLabelInterface> []

}

const CalendarDrawerSection = ({title, children}: CalendarDrawerSectionInterface) => {
    return (
        <div className={"mb-4 last:mb-0"}>
            <CalendarDrawerTitle text={title}/>
            <div>
                {children}
            </div>
        </div>
    )
}

export default CalendarDrawerSection