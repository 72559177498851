import React, {useEffect, useState} from "react";
import Card from "../organisms/Card";
import {alertType} from "../../utils/TypeUtils";
import moment from "moment";
import {NORMAL_DATE_FORMAT_WITH_TIME} from "../../helper/DateHelper";
import sendRequest from "../../ApiManager/ApiManager";
import Table from "../organisms/Table";
import TableHead from "../molecules/table/TableHead";
import TableBodyRow from "../molecules/table/TableBodyRow";
import TableTH from "../atoms/table/TableTH";
import TableBody from "../molecules/table/TableBody";
import Badge, {badgeType} from "../atoms/Badge";
import TableTD from "../atoms/table/TableTD";
import CardTitle from "../atoms/CardTitle";

interface logsInterface {
  id: number,
  action: string,
  created_at: string,
  warning_level: alertType,
  user_email: string,
}

const getBadgeType = (warningLevel: string): badgeType => {
  switch (warningLevel) {
    case "info":
      return "default"
    case "warning":
      return "orange"
    default:
      return "red"
  }
}

const Logs = () => {
  const [logs, setLogs] = useState<logsInterface[]>([]);

  useEffect(() => {
    sendRequest('get', '/logs')
      .then((response) => setLogs(response.data))
      .catch()
  }, [])

  return (
    <Card>
      <CardTitle text={"Logi z systemu"}/>
      <div className={"mt-4 mb-4"}>
        <Table>
          <TableHead>
            <TableTH className={"w-1/12"}>#</TableTH>
            <TableTH className={"w-2/12"}>Poziom</TableTH>
            <TableTH className={"w-3/12"}>Użytkownik</TableTH>
            <TableTH className={"w-4/12"}>Akcja</TableTH>
            <TableTH className={"w-2/12"}>Data i czas</TableTH>
          </TableHead>
          <TableBody>
            {
              logs.map(log => {
                return <TableBodyRow border hover>
                  <TableTD>{log.id}</TableTD>
                  <TableTD>
                    <Badge badgeType={getBadgeType(log.warning_level)} text={log.warning_level}/>
                  </TableTD>
                  <TableTD>{log.user_email}</TableTD>
                  <TableTD>{log.action}</TableTD>
                  <TableTD>{moment(log.created_at).format(NORMAL_DATE_FORMAT_WITH_TIME)}</TableTD>
                </TableBodyRow>
              })
            }
          </TableBody>
        </Table>
      </div>
    </Card>
  )
}

export default Logs;