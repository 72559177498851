import React, {useEffect, useState} from "react";
import Heading from "../../components/utils/Heading";
import RoomCard from "../../components/utils/RoomCard";
import Card from "../../components/card/Card";
import sendRequest from "../../ApiManager/ApiManager";

interface roomsInterface {
    id: number,
    name: string,
    single_beds: number,
    double_beds: number,
    price_per_day: number
}

function RoomList() {
    const [rooms, setRooms] = useState<roomsInterface[]>([]);

    useEffect(() => {
        (async () => {
            const response = await sendRequest(
                'get',
                '/rooms'
            );
            const data = await response.data

            setRooms(data)
        })()
    }, [])

    return (
        <Card>
            <div className={"w-full"}>
                <Heading size={2} text={"Lista pokoi"}/>
                <div className={"grid sm:grid-cols-2 xl:grid-cols-3 gap-4"}>
                    {
                        rooms.map(room => {
                            return <RoomCard
                                id={room.id}
                                name={room.name}
                                singleBeds={room.single_beds}
                                doubleBeds={room.double_beds}
                                price={room.price_per_day}
                                key={room.id}
                            />
                        })
                    }
                </div>
            </div>
        </Card>
    )
}

export default RoomList;