import React from "react";

interface SelectItemInterface {
    value: string | number
    text: string | number
    disabled?: boolean
    onSelect?: (selectedValue: string | number, selectedText: string | number) => void
}

const SelectItem = ({value, text, disabled, onSelect}: SelectItemInterface) => {
    const disableStyle = disabled && "cursor-not-allowed !text-gray-400"
    return (
        <li>
            <button type="button"
                    className={disableStyle+" inline-flex w-full px-4 py-2 text-sm text-gray-300 hover:text-gray-100 hover:bg-gray-600"}
                    onClick={() => !onSelect ? {} : onSelect(value, text)}
            >
                {text}
            </button>
        </li>
    )
}

export default SelectItem