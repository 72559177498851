import {NavigateItemInterface} from "./NavigateItem";
import React from "react";
import {NavigateDropdownItemInterface} from "./NavigateDropdownItem";

export interface NavigateListInterface {
    children: React.ReactElement<NavigateItemInterface> | React.ReactElement<NavigateItemInterface>[] | React.ReactElement<NavigateDropdownItemInterface> | React.ReactElement<NavigateDropdownItemInterface>[]
    className?: string
}

const NavigateList = ({children, className}: NavigateListInterface) => {
    return (
        <ul className={"space-y-2 font-normal " + className}>
            {children}
        </ul>
    )
}

export default NavigateList