import React, {useEffect} from "react";
import Heading from "../../components/utils/Heading";
import Card from "../../components/card/Card";
import RoomForm from "../../components/form/pages/RoomForm";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {sendFetchRoomRequest} from "../../redux/features/RoomManage";
import {useNavigate, useParams} from "react-router-dom";
import {parseToInt} from "../../components/Utils";
import {ROUTE_ROOM_CREATE} from "../../Settings";

function RoomUpdate() {
    const manageRoom = useAppSelector(state => state.roomManage)
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const params = useParams()

    const roomId = parseToInt(params.roomId)

    useEffect(() => {
        dispatch(sendFetchRoomRequest(roomId))
        if ('pending' !== manageRoom.requestData.status && manageRoom.requestData.error) {
            navigate(ROUTE_ROOM_CREATE)
        }
    }, [dispatch, manageRoom.requestData.error, manageRoom.requestData.status, navigate, roomId])

    return (
        <Card>
            <div>
                <Heading size={3} text={"Edytuj pokój #" + roomId}/>
                <RoomForm roomId={roomId}/>
            </div>
        </Card>
    )
}

export default RoomUpdate;