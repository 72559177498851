import {ReactElement} from "react";
import {TableTHInterface} from "../../atoms/table/TableTH";

interface TableHeadInterface {
    children?: ReactElement<TableTHInterface> | ReactElement<TableTHInterface>[]
}

const TableHead = ({children}: TableHeadInterface) => {
    return (
        <thead className={"text-sm bg-gray-700 text-gray-400 uppercase"}>
        <tr>{children}</tr>
        </thead>
    )
}

export default TableHead