import React, {SetStateAction, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import useClickOutside from "../../../hooks/useClickOutside";
import InputLabel from "../../atoms/input/InputLabel";
import SelectItem from "../../atoms/input/SelectItem";
import InputError from "../../atoms/input/InputError";

const selectFromList = "Wybierz z listy";

interface SelectItemInterface {
  value: string | number
  text: string | number
  selected?: boolean
}

interface SelectInterface {
  label: string
  inputName: string
  errorMessage?: string
  disabled?: boolean
  onChange?: React.Dispatch<SetStateAction<string>>
  items: SelectItemInterface[]
}

const Select = ({
                  label,
                  inputName,
                  errorMessage,
                  disabled,
                  onChange,
                  items
                }: SelectInterface) => {

  const [value, setValue] = useState<string | number>("")
  const [valueText, setValueText] = useState<string | number>("")
  const {ref, status, visible, handleClick, setVisible} = useClickOutside();

  const disabledCursor = disabled && "cursor-not-allowed "

  useEffect(() => {
    const selectedItems = items.filter(item => item.selected)
    if (selectedItems.length > 0) {
      setValueText(selectedItems[0].text)
      setValue(selectedItems[0].value)
    }
  }, [])

  const handleSelect = (selectedValue: string | number, selectedText: string | number) => {
    setValue(selectedValue)
    setValueText(selectedText)
    setVisible(false)
    if (onChange) {
      onChange(selectedValue.toString())
    }
  }

  const handleViewList = () => {
    setVisible(true)
  }

  return (<div className={"w-full space-y-2"}>
    <InputLabel label={label} inputName={inputName} isRequired={false}/>

    <div className={"h-10 items-center"}>
      <button data-dropdown-toggle={"dropdown-" + inputName}
              onClick={handleViewList}
              className="w-full flex-shrink-0 z-50 inline-flex items-center py-2.5 px-4 text-sm text-center text-gray-100 bg-gray-700 border border-none rounded-lg focus:ring-2 focus:ring-orange-400 outline-none"
              type="button">
        <div className={"flex items-center justify-between w-full"}>
          <span>{valueText ?? selectFromList}</span>
          <FontAwesomeIcon icon={visible ? faChevronUp : faChevronDown}/>
        </div>
      </button>
      <div id={"dropdown-" + inputName} className={"relative w-full " + status} ref={ref}>
        <div className={"absolute bg-gray-700 border-gray-500 border w-full mt-2 rounded-lg z-10"}>
          <ul className={"py-2 text-sm text-gray-200"} id={"test"}>
            <SelectItem value={""} text={selectFromList} disabled/>
            {
              items
                .sort((a, b) => {
                  if (a.selected === true && b.selected !== true) {
                    return -1;
                  }
                  if (b.selected === true && a.selected !== true) {
                    return 1;
                  }
                  return 0;
                })
                .map(item => {
                  return <SelectItem
                    value={item.value}
                    text={item.text}
                    onSelect={handleSelect}
                    key={Math.random()}
                  />
                })
            }
          </ul>
        </div>
      </div>
    </div>
    <InputError errorMessage={errorMessage}/>
    <input type={"hidden"} value={value} name={inputName} className={"hidden"}/>
  </div>)
}

export default Select