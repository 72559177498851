import React, {useEffect} from 'react';
import './index.css';
import {Outlet, useNavigate} from "react-router-dom";
import useAuthorization from "./hooks/useAuthorization";
import Navigate from "./components/organisms/Navigate";
import {ROUTE_LOGIN} from "./consts/Routes";
import moment from "moment";
import 'moment/locale/pl';

function App() {
  const authorization = useAuthorization();
  const navigate = useNavigate()
  moment.locale('pl')

  useEffect(() => {
    if (!authorization.isUserLoggedIn()) {
      navigate(ROUTE_LOGIN)
    }
  })

  return (
    <div className={"min-h-screen bg-gray-900 flex flex-col lg:flex-row w-full"}>
      <Navigate/>
      <main className={"w-full h-full overflow-y-auto ml-64 px-8 pt-8"}>
        <Outlet/>
      </main>
    </div>
  )
}

export default App;
