import {validatorMessages} from "../utils/ValidatorMessages";
import {setProperty} from "dot-prop";

interface errorResponseMessageInterface {
    field: string,
    message: string
}

interface errorResponseInterface {
    http_error: number,
    type: string,
    "message": errorResponseMessageInterface[]
}

export const clearErrorForField = (state: object, field: string) => {
    return setProperty(state, field, undefined)
}

export const checkErrorHandler = (state: object, errorResponse: errorResponseInterface) => {
    let stateWithErrors = state

    errorResponse.message.map(value => {
        const fieldName = value.field
            .replace(/(\[\d+\])/gi, ".")
            .replace(/\]\[|\[\]/gi, ".")
            .replace(/(\]|\[)/gi, "")

        let message = value.message
        validatorMessages.map(translation => {
            message = message.replace(
                translation.source,
                translation.target
            )
        })

        setProperty(stateWithErrors, fieldName, message)
    })

    return stateWithErrors
}