import React from "react";
import Select from "../form/Select";
import Input from "../form/Input";
import FormRow from "../form/FormRow";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {manageInvoiceActions} from "../../redux/features/InvoiceManage";
import {messages} from "../../Settings";

interface invoiceFomRowProps {
    id: number,
    isDeletable: boolean
}

function InvoiceFormRow(invoiceFormRowProps: invoiceFomRowProps) {
    const manageInvoice = useAppSelector(state => state.invoiceManage)
    const dispatch = useAppDispatch();
    let invoiceItemsSelect = [];

    const {locale} = manageInvoice.formData
    const selectMessages = 'pl' === locale
        ? messages.invoices.items.pl
        : messages.invoices.items.en

    invoiceItemsSelect.push({
        value: selectMessages.selectText,
        text: selectMessages.selectText,
        disabled: true
    });

    for (let i = 1; i <= 5; i++) {
        invoiceItemsSelect.push({
            value: selectMessages.itemText.replace("%number%", i.toString()),
            text: selectMessages.itemText.replace("%number%", i.toString()),
        })
    }
    invoiceItemsSelect.push({
        value: selectMessages.otherItemText,
        text: selectMessages.otherItemText
    })

    const namePrefix = "invoice_item[" + invoiceFormRowProps.id + "]."
    const placeholderForPrice = Math.random() * (500 - 150) + 150;

    const handleDeleteInvoiceFormRow = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        dispatch(manageInvoiceActions.deleteInvoiceRow(invoiceFormRowProps.id))
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const fieldName = name.split(".")[1]

        const dataToDispatch = {
            itemId: invoiceFormRowProps.id,
            data: {
                [fieldName]: event.target.value
            }
        }

        dispatch(manageInvoiceActions.addDataToInvoiceRow(dataToDispatch))
    }

    const deleteButton = (
        <div className={"w-full xl:w-16 flex items-end justify-center pt-1.5 xl:pt-0 pb-1.5"}>
            <button
                className={"text-gray-300"}
                onClick={handleDeleteInvoiceFormRow}
            >
                <i className="fa-solid fa-trash fa-xl"></i>
            </button>
        </div>
    );

    return (
        <FormRow>
            <div className={"w-5/6"}>
                <Select
                    name={namePrefix + "name"}
                    title={"Pozycja na fakturze"}
                    isRequired={true}
                    options={invoiceItemsSelect}
                    onChange={handleInput}
                    value={manageInvoice.invoiceItemsSettings.items[invoiceFormRowProps.id].data.name}
                />
            </div>
            <div className={"w-1/6"}>
                <Input
                    type={"number"}
                    name={namePrefix + "quantity"}
                    title={"Ilość dób"}
                    placeholder={"1"}
                    step={1}
                    min={1}
                    max={366}
                    defaultValue={manageInvoice.invoiceItemsSettings.items[invoiceFormRowProps.id].data.quantity}
                    isRequired={true}
                    onChange={handleInput}
                />
            </div>
            <div className={"w-1/6"}>
                <Input
                    type={"number"}
                    name={namePrefix + "price"}
                    title={"Kwota brutto za dobę"}
                    placeholder={placeholderForPrice.toFixed(2)}
                    step={0.01}
                    isRequired={true}
                    onChange={handleInput}
                />
            </div>
            <>
                {invoiceFormRowProps.isDeletable && deleteButton}
            </>
        </FormRow>
    )
}

export default InvoiceFormRow;