import React from "react";

interface availableRoomProps {
    roomId: number
    name: string
    singleBeds: number
    doubleBeds: number
    pricePerDay: number,
    onClick?: React.MouseEventHandler
}

function availableRoom(availableRoomProps: availableRoomProps) {
    return (
        <div className={"w-full p-4 rounded-lg bg-gray-700 text-sm text-left hover:opacity-80 cursor-pointer"}
             onClick={availableRoomProps.onClick}>
            <div className={"flex flex-col text-center xl:text-left xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2"}>
                <div className={"w-full xl:w-2/12"}>
                    <span>{availableRoomProps.name}</span>
                </div>
                <div className={"space-x-1.5 w-full xl:w-3/12"}>
                    <span><i className="fa-solid fa-user"></i></span>
                    <span>Jednoosobowe:</span>
                    <span>{availableRoomProps.singleBeds}</span>
                </div>
                <div className={"space-x-1.5 w-full xl:w-3/12"}>
                    <span><i className="fa-solid fa-user-group"></i></span>
                    <span>Dwuosobowe:</span>
                    <span>{availableRoomProps.doubleBeds}</span>
                </div>
                <div className={"space-x-1.5 w-full xl:w-4/12"}>
                    <span><i className="fa-solid fa-sack-dollar"></i></span>
                    <span>Kwota za dobę:</span>
                    <span>{availableRoomProps.pricePerDay}zł</span>
                </div>
            </div>
        </div>
    )
}

export default availableRoom;