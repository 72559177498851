import React from "react";
import {buttonConfig, colorType} from "../Utils";

type buttonType = 'reset' | 'submit' | 'button'

interface ButtonProps {
    readonly type: colorType;
    readonly text: string;
    readonly handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    buttonType?: buttonType
}

function FormButton(buttonProps: ButtonProps) {
    const buttonClasses = buttonConfig[buttonProps.type].classes;

    return (
        <button
            onClick={buttonProps.handleClick}
            type={buttonProps.buttonType ?? 'submit'}
            className={buttonClasses + " text-white w-full h-12 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"}>
            {buttonProps.text}
        </button>
    );
}

export default FormButton