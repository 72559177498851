import React, {useEffect} from "react";
import Heading from "../../components/utils/Heading";
import Card from "../../components/card/Card";
import {useAppDispatch, useAppSelector} from "../../hooks";
import ReservationForm from "../../components/form/pages/ReservationForm";
import {fetchReservation, manageReservationActions} from "../../redux/features/ManageReservation";
import {useNavigate, useParams} from "react-router-dom";
import {parseToInt} from "../../components/Utils";
import {ROUTE_RESERVATION_CREATE} from "../../Settings";

function ReservationUpdate() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const params = useParams()

    const manageReservation = useAppSelector(state => state.manageReservation)
    const reservationId = parseToInt(params.reservationId)

    useEffect(() => {
        dispatch(manageReservationActions.clearStore())
        dispatch(fetchReservation(reservationId))
        if (null !== manageReservation.fetchRequest.errors) {
            navigate(ROUTE_RESERVATION_CREATE)
        }
    }, [dispatch, manageReservation.fetchRequest.errors, navigate, reservationId])

    return (
        <Card>
            <div>
                <Heading size={3} text={"Edytuj rezerwację"}/>
                <ReservationForm reservationId={reservationId}/>
            </div>
        </Card>
    )
}

export default ReservationUpdate;