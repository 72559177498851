import React from "react";

interface FormRowsProps {
    children: JSX.Element[] | JSX.Element
}

function FormRow(formRowProps: FormRowsProps) {
    return (
        <div className={"w-full mb-5 flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-2.5"}>
            {
                !Array.isArray(formRowProps.children)
                    ? formRowProps.children
                    :
                    formRowProps.children.map(child => {
                        return child
                    })
            }
        </div>
    )
}

export default FormRow;