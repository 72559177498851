import React from "react";

export interface TableTDInterface {
    children?: string | number | React.JSX.Element | React.JSX.Element[] | string[] | number[] | (string | number)[]
    className?: string
    rowSpan?: number
    colSpan?: number
}

const TableTD = ({children, className, rowSpan, colSpan}: TableTDInterface) => {
    return (<td scope={"row"} rowSpan={rowSpan} colSpan={colSpan} className={`py-3 px-6 ${className}`}>{children}</td>)
}

export default TableTD