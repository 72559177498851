import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {NavLink} from "react-router-dom";
import React from "react";
import NavigateIcon from "../../atoms/NavigateIcon";

export interface NavigateItemInterface {
    location: string
    text: string
    icon?: IconProp
}

const NavigateItem = ({location, text, icon}: NavigateItemInterface) => {
    return (
        <li>
            <NavLink
                to={location}
                className={
                    ({isActive}) =>
                        (isActive ? "text-white bg-gray-700" : "text-gray-400 hover:bg-gray-700 hover:text-white")
                        + " flex items-center p-2 rounded-lg group"}>
                <NavigateIcon icon={icon}/>
                <span className="ml-3">{text}</span>
            </NavLink>
        </li>
    )
}

export default NavigateItem