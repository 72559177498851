import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../hooks";
import {manageAuthActions} from "../../redux/features/AuthorizationManage";
import {useEffect} from "react";
import { localStorageLocation } from "../../Settings";

function Logout() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    dispatch(manageAuthActions.logout())

    useEffect(() => {
        navigate("/login", {replace: true})
        localStorage.removeItem(localStorageLocation)
    }, [dispatch, navigate])


    return (<div></div>)
}

export default Logout