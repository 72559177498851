import moment from "moment/moment";
import {badgeType} from "../components/atoms/Badge";
import {PaymentStatusType} from "./TypeUtils";

const getBadgeTypeColorForReservedRoomStatus = (startDateUnix: number, endDateUnix: number, currentUnix: number): badgeType => {
  if (startDateUnix === currentUnix) {
    return "green"
  } else if (endDateUnix === currentUnix) {
    return "red"
  } else if (currentUnix > endDateUnix) {
    return "orange"
  } else if (startDateUnix > currentUnix) {
    return "light-orange"
  } else if (startDateUnix < currentUnix && currentUnix < endDateUnix) {
    return "yellow"
  }

  return "red"
}

const getTranslatableReservedRoomStatus = (startDateUnix: number, endDateUnix: number, currentUnix: number): string => {

  if (startDateUnix === currentUnix) {
    return "Zameldowanie"
  } else if (endDateUnix === currentUnix) {
    return "Wymeldowanie"
  } else if (currentUnix > endDateUnix) {
    return "Po terminie"
  } else if (startDateUnix > currentUnix) {
    return "Przed terminem"
  } else if (startDateUnix < currentUnix && currentUnix < endDateUnix) {
    return "W trakcie"
  }

  return "Nie wiadomo"
}

const getSearchReservedRoomStatus = (startDateUnix: number, endDateUnix: number, currentUnix: number): string => {

  if (startDateUnix === currentUnix) {
    return "check-in"
  } else if (endDateUnix === currentUnix) {
    return "check-out"
  } else if (currentUnix > endDateUnix) {
    return "after"
  } else if (startDateUnix > currentUnix) {
    return "before"
  } else if (startDateUnix < currentUnix && currentUnix < endDateUnix) {
    return "during"
  }

  return "unknown"
}

export const getReservedRoomStatus = (startDate: string, endDate: string, currentDate?: string) => {
  const startDateUnix = moment(startDate).set({hour: 0, minute: 0, second: 0}).unix()
  const endDateUnix = moment(endDate).set({hour: 0, minute: 0, second: 0}).unix()
  const currentUnix = moment(currentDate).set({hour: 0, minute: 0, second: 0}).unix()

  const translatableReservedRoomStatus = getTranslatableReservedRoomStatus(startDateUnix, endDateUnix, currentUnix)
  const badgeTypeColorForReservedRoomStatus = getBadgeTypeColorForReservedRoomStatus(startDateUnix, endDateUnix, currentUnix)
  const searchReservedRoomStatus = getSearchReservedRoomStatus(startDateUnix, endDateUnix, currentUnix)


  return {badgeTypeColorForReservedRoomStatus, searchReservedRoomStatus, translatableReservedRoomStatus}
}

export const getBadgeTypeColorForReservationPaymentStatus = (paymentStatus: PaymentStatusType): badgeType => {
  switch (paymentStatus) {
    case "paid":
      return "green"
    case "unpaid":
      return "red"
    case "prepaid":
      return "yellow"
  }

}