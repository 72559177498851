import React from "react";
import {Link} from "react-router-dom";
import {ROUTE_ROOM_UPDATE} from "../../Settings";
import {formattedPriceView} from "../../helper/PriceHelper";

interface RoomCardProps {
    id: number,
    name: string;
    singleBeds: number;
    doubleBeds: number;
    price: number;
}

function RoomCard(roomCardProps: RoomCardProps) {
    const linkToUpdateRoom = ROUTE_ROOM_UPDATE.replace(":roomId", roomCardProps.id.toString())
    return (
        <Link to={linkToUpdateRoom}
              className={"flex flex-col justify-center items-center text-gray-300 border border-gray-900 bg-gray-700 space-y-3 px-6 w-full h-48 shadow-lg rounded-md hover:shadow-gray-800 cursor-pointer"}>
            <div className="w-full flex justify-center space-x-2 px-2 pb-2 text-base font-bold">
                <span>{roomCardProps.name}</span>
            </div>
            <div className="w-full flex justify-center space-x-2 px-2 pb-1 text-sm border-b border-gray-300">
                <span>
                    <i className="fa-solid fa-user"></i>
                </span>
                <span>
                    Łóżka jednoosobowe: {roomCardProps.singleBeds}
                </span>
            </div>
            <div className="w-full flex justify-center space-x-2 px-2 pb-1 text-sm border-b border-gray-300">
                <span>
                    <i className="fa-solid fa-user-group"></i>
                </span>
                <span>
                    Łóżka dwuosobowe: {roomCardProps.doubleBeds}
                </span>
            </div>
            <div className="w-full flex justify-center space-x-2 px-2 pb-1 text-sm border-b border-gray-300">
                <span>
                    <i className="fa-solid fa-sack-dollar"></i>
                </span>
                <span>
                    Cena za dobę brutto: {formattedPriceView(roomCardProps.price)}
                </span>
            </div>
        </Link>
    );
}

export default RoomCard;