/* Login routes */
export const ROUTE_LOGIN = "/login"
export const ROUTE_LOGOUT = "/logout"

/* Miscellaneous routes */
export const ROUTE_DASHBOARD = "/dashboard"
export const ROUTE_CALENDAR = ROUTE_DASHBOARD + "/calendar"
export const ROUTE_REPORTS = ROUTE_DASHBOARD + "/reports"
export const ROUTE_LOGS = ROUTE_DASHBOARD + "/logs"

/* Invoices routes */
export const ROUTE_INVOICES_CREATE = ROUTE_DASHBOARD + "/invoices/create"
export const ROUTE_INVOICES_LIST = ROUTE_DASHBOARD + "/invoices/list"

/* Rooms routes */
export const ROUTE_ROOM_CREATE = ROUTE_DASHBOARD + "/rooms/create"
export const ROUTE_ROOM_UPDATE = ROUTE_DASHBOARD + "/rooms/update/:roomId"
export const ROUTE_ROOM_LIST = ROUTE_DASHBOARD + "/rooms/list"

/* Reservations routes */
export const ROUTE_RESERVATION_CREATE = ROUTE_DASHBOARD + "/reservations/create"
export const ROUTE_RESERVATION_UPDATE = ROUTE_DASHBOARD + "/reservations/update/:reservationId"