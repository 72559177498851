import React from "react";
import Heading from "../../components/utils/Heading";
import Card from "../../components/card/Card";
import RoomForm from "../../components/form/pages/RoomForm";
import {useAppDispatch} from "../../hooks";
import {manageRoomActions} from "../../redux/features/RoomManage";

function RoomCreate() {
    const dispatch = useAppDispatch()

    dispatch(manageRoomActions.clearStore())

    return (
        <Card>
            <div>
                <Heading size={3} text={"Dodaj pokój"}/>
                <RoomForm/>
            </div>
        </Card>
    )
}

export default RoomCreate;