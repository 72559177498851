import {
  faCalendarDays,
  faCalendarPlus,
  faChartPie,
  faFileInvoiceDollar,
  faFilePdf, faGears
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import NavigateSidebar from "../molecules/navigate/NavigateSidebar";
import NavigateUser from "../molecules/navigate/NavigateUser";
import NavigateList from "../molecules/navigate/NavigateList";
import NavigateItem from "../molecules/navigate/NavigateItem";
import NavigateDropdown from "../molecules/navigate/NavigateDropdown";
import NavigateDropdownItem from "../molecules/navigate/NavigateDropdownItem";
import {
  ROUTE_CALENDAR,
  ROUTE_DASHBOARD, ROUTE_INVOICES_CREATE, ROUTE_INVOICES_LIST, ROUTE_LOGS, ROUTE_REPORTS,
  ROUTE_RESERVATION_CREATE,
  ROUTE_ROOM_CREATE,
  ROUTE_ROOM_LIST
} from "../../consts/Routes";

const Navigate = () => {
    return (
        <NavigateSidebar>
            <NavigateUser/>
            <NavigateList>
                <NavigateItem location={ROUTE_DASHBOARD+"/"} text={"Dashboard"} icon={faChartPie}/>
                <NavigateItem location={ROUTE_CALENDAR} text={"Kalendarz"} icon={faCalendarDays}/>
                <NavigateItem location={ROUTE_RESERVATION_CREATE} text={"Dodaj rezerwację"} icon={faCalendarPlus}/>
                <NavigateDropdown text={"Pokoje"} icon={faCalendarPlus}>
                    <NavigateDropdownItem location={ROUTE_ROOM_CREATE} text={"Dodaj pokój"}/>
                    <NavigateDropdownItem location={ROUTE_ROOM_LIST} text={"Lista pokoi"}/>
                </NavigateDropdown>
                <NavigateDropdown text={"Faktury"} icon={faFileInvoiceDollar}>
                    <NavigateDropdownItem location={ROUTE_INVOICES_CREATE} text={"Dodaj fakturę"}/>
                    <NavigateDropdownItem location={ROUTE_INVOICES_LIST} text={"Lista faktur"}/>
                </NavigateDropdown>
                <NavigateItem location={ROUTE_REPORTS} text={"Raporty"} icon={faFilePdf}/>
              <NavigateDropdown text={"Zarządzanie"} icon={faGears}>
                <NavigateDropdownItem location={ROUTE_LOGS} text={"Logi"}/>
              </NavigateDropdown>
            </NavigateList>
        </NavigateSidebar>
    )
}

export default Navigate