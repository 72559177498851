import React, {useEffect, useState} from "react";
import HelpLabel from "./HelpLabel";
import ErrorLabel from "./ErrorLabel";

interface TextareaInterface {
    name: string;
    title: string;
    rows?: number;
    placeholder?: string;
    isRequired?: boolean;
    defaultValue?: string | number | undefined
    errorMessage?: string
    onChange?: React.ChangeEventHandler
}

function Textarea(textarea: TextareaInterface) {
    const [defaultValue, setDefaultValue] = useState<string | number | undefined>()
    const errorRing = textarea.errorMessage && "ring-red-600 ring-2 "

    useEffect(() => {
        setDefaultValue(textarea.defaultValue)
    }, [textarea.defaultValue])

    return (
        <div>
            <HelpLabel name={textarea.name} title={textarea.title} required={textarea.isRequired}/>
            <textarea
                id={textarea.name}
                name={textarea.name}
                placeholder={textarea.placeholder}
                required={textarea.isRequired}
                rows={textarea.rows ?? 4}
                onChange={textarea.onChange}
                defaultValue={defaultValue}
                className={errorRing+"border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-none placeholder-gray-400 text-gray-100 focus:ring-2 focus:ring-orange-400 outline-none"}
            />
            <ErrorLabel errorMessage={textarea.errorMessage}/>
        </div>
    );
}

export default Textarea;