import React from "react";
import InvoiceForm from "../../components/invoices/InvoiceForm";

function InvoiceCreate() {
    return (
        <div className={"w-full flex flex-col items-center"}>
            <InvoiceForm/>
        </div>
    )
}

export default InvoiceCreate;