interface CalendarMonthAndYearInterface {
    month: string
    year: string | number
}

const CalendarMonthAndYear = ({month, year}: CalendarMonthAndYearInterface) => {
    return (<span className={"text-xl font-bold text-gray-200 first-letter:uppercase"}>{month} {year}</span>)
}


export default CalendarMonthAndYear