import {NavLink} from "react-router-dom";
import React from "react";

export interface NavigateDropdownItemInterface {
    location: string
    text: string
}

const NavigateDropdownItem = ({location, text}: NavigateDropdownItemInterface) => {
    return (
        <li>
            <NavLink
                to={location}
                className={
                    ({isActive}) =>
                        (isActive ? "text-white bg-gray-700" : "text-gray-400 hover:bg-gray-700 hover:text-white")
                        + " flex items-center p-2 rounded-lg group"}>
                <span className="ml-8">{text}</span>
            </NavLink>
        </li>
    )
}

export default NavigateDropdownItem