import React from "react";
import InputLabel from "../../atoms/input/InputLabel";
import InputError from "../../atoms/input/InputError";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type inputType = 'text' | 'email' | 'date' | 'number' | 'password';

interface InputInterface {
  type: inputType
  label: string
  inputName: string
  placeholder?: string
  isRequired?: boolean;
  defaultValue?: string | number | undefined
  errorMessage?: string
  min?: string | number | undefined;
  max?: string | number | undefined;
  step?: number | undefined;
  disabled?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onClick?: React.MouseEventHandler<HTMLInputElement>
  icon?: IconProp
}

const Input = ({
                 type,
                 label,
                 inputName,
                 placeholder,
                 isRequired,
                 defaultValue,
                 errorMessage,
                 min,
                 max,
                 step,
                 disabled,
                 onClick,
                 onChange,
                 icon
               }: InputInterface) => {

  const disabledCursor = disabled && "cursor-not-allowed "
  const errorRing = errorMessage && "focus:ring-red-400 !border-red-500 "

  const additionClasses = (disabledCursor ?? "") + (errorRing ? errorRing : "border-none ") + (icon ? "pl-10 " : "")

  return (
    <div className={"w-full space-y-2"}>
      <InputLabel label={label} inputName={inputName} isRequired={isRequired ?? false}/>
      <div className={"h-10 flex items-center justify-center relative"}>
        {
          icon && <div className="absolute z-10 inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <FontAwesomeIcon icon={icon}/>
          </div>
        }
        <input
          id={inputName}
          name={inputName}
          type={type}
          placeholder={placeholder}
          required={isRequired}
          defaultValue={defaultValue}
          min={min}
          max={max}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
          step={step}
          className={additionClasses + " border h-10 box-border text-sm rounded-lg block w-full p-2.5 bg-gray-700 placeholder-gray-400 text-gray-100 focus:ring-1 focus:ring-orange-400 outline-none"}
        />
      </div>
      <InputError errorMessage={errorMessage}/>
    </div>
  )
}

export default Input