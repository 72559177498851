import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface CalendarDrawerReservationGuestLabelInterface {
    guestTitle: string
    guestText: string
    icon: IconProp
    isEmail: boolean
    isPhone: boolean
}

const CalendarDrawerReservationGuestLabel = (
    {
        guestTitle,
        guestText,
        icon,
        isEmail,
        isPhone
    }: CalendarDrawerReservationGuestLabelInterface) => {
    return (
        <div className={"flex items-center justify-between py-4 border-b border-gray-700 last:border-none"}>
            <div className={"space-x-2 text-gray-400"}>
                <FontAwesomeIcon icon={icon}/>
                <span className={"text-sm"}>{guestTitle}</span>
            </div>
            <span className={"text-gray-400 text-sm"}>
                {
                    isEmail && !isPhone
                        ? <a href={`mailto:${guestText.replace(/\s/g, "")}`}>{guestText}</a>
                        : isPhone && !isEmail
                            ? <a href={`tel:${guestText.replace(/\s/g, "")}`}>{guestText}</a>
                            : guestText
                }
            </span>
        </div>
    )
}

export default CalendarDrawerReservationGuestLabel