import React from "react";
import {NavigateListInterface} from "./NavigateList";

interface NavigateSidebarInterface {
    children: React.ReactElement<NavigateListInterface> | React.ReactElement<NavigateListInterface>[]
}

const NavigateSidebar = ({children}: NavigateSidebarInterface) => {
    return (
        <aside
            className={"fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 font-inter"}>
            <div className="h-full px-3 py-4 overflow-y-auto bg-gray-800">
                {children}
            </div>
        </aside>
    )
}

export default NavigateSidebar