import {CalendarReservedRoomInterface, ReservationInterface} from "../utils/Entities";
import moment from "moment";
import {useState} from "react";


export interface ReservedRoomsForDatesInterface {
    [date: string]: CalendarReservedRoomInterface[]
}

const useCalendarReservedRoom = () => {
    const [calendarReservedRooms, setCalendarReservedRooms] = useState<ReservedRoomsForDatesInterface>({})
    const mapCalendarReservedRooms = (reservations: ReservationInterface[]) => {
        const localCalendarReservedRooms: ReservedRoomsForDatesInterface = {}

        reservations.map(reservation => {
            reservation.reserved_rooms.map(reservedRoom => {
                const startDate = moment(reservedRoom.start_date)
                const endDate = moment(reservedRoom.end_date)

                const diffDates = endDate.diff(startDate, "days")

                for (let i = 0; i <= Math.abs(diffDates); i++) {
                    const formattedDate: string = moment(startDate).format("YYYY-MM-DD")
                    const calendarReservedRoom: CalendarReservedRoomInterface = {
                        reservationId: reservation.id,
                        roomName: reservedRoom.room_name,
                        paymentStatus: reservation.payment_status,
                        reservationSource: reservation.reservation_source
                    }

                    if (undefined === localCalendarReservedRooms[formattedDate]) {
                        localCalendarReservedRooms[formattedDate] = []
                    }

                    localCalendarReservedRooms[formattedDate].push(calendarReservedRoom)

                    startDate.add(1, "day")
                }
            })
        })

        setCalendarReservedRooms(localCalendarReservedRooms)
    }

    return {mapCalendarReservedRooms, calendarReservedRooms}
}

export default useCalendarReservedRoom