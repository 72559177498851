export const monthsName: any = {
    1: "Styczeń",
    2: "Luty",
    3: "Marzec",
    4: "Kwiecień",
    5: "Maj",
    6: "Czerwiec",
    7: "Lipiec",
    8: "Sierpień",
    9: "Wrzesień",
    10: "Październik",
    11: "Listopad",
    12: "Grudzień",
};

export const dayName: any = {
  0: "PON",
  1: "WT",
  2: "ŚR",
  3: "CZW",
  4: "PT",
  5: "SOB",
  6: "NIEDZ",
}