import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_LOGOUT} from "../../../consts/Routes";
import useAuthorization from "../../../hooks/useAuthorization";

interface NavigateUserInterface {

}

const NavigateUser = ({}: NavigateUserInterface) => {
    const {getUsername} = useAuthorization();

    return (
        <div className={"w-full relative flex flex-col items-center mb-4 space-y-4 pb-4 border-b border-gray-700"}>
            <img
                className={"w-32"}
                alt={"Logo VillaRenoma"}
                src={"/villarenoma_logo.png"}/>
            <div className={"flex flex-col items-center text-center"}>
                <span className={"text-gray-300 font-bold text-xl"}>{getUsername()}</span>
                <span className={"text-gray-500 text-sm"}>@vrsystem.pl</span>
            </div>
            <Link to={ROUTE_LOGOUT} className={"w-full"}>
                <button
                    className={"border border-gray-600 w-full h-10 rounded-lg hover:bg-gray-700 text-gray-400 text-sm cursor-pointer"}
                >
                    <FontAwesomeIcon icon={faRightFromBracket} className={"mr-2"}/>
                    <span>Wyloguj z systemu</span>
                </button>
            </Link>
        </div>
    )
}

export default NavigateUser