import CalendarDrawerSection from "../molecules/calendar/CalendarDrawerSection";
import CalendarDrawerReservationLabel from "../molecules/calendar/CalendarDrawerReservationLabel";
import {
    faClose,
    faEnvelope, faNoteSticky, faPenToSquare,
    faSquarePhone
} from "@fortawesome/free-solid-svg-icons";
import CalendarDrawerReservationGuestLabel from "../molecules/calendar/CalendarDrawerReservationGuestLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {ReservationInterface} from "../../utils/Entities";
import {formattedPriceView} from "../../helper/PriceHelper";
import {PaymentMethodType, PaymentStatusType, ReservationSourceType} from "../../utils/TypeUtils";
import {colorType} from "../atoms/calendar/CalendarBadge";
import {
    formatPhoneNumber,
    getTranslatablePaymentMethod,
    getTranslatablePaymentStatus,
    getTranslatableReservationSource
} from "../../utils/Utils";
import moment from "moment";
import {NORMAL_DATE_FORMAT} from "../../helper/DateHelper";
import {Link} from "react-router-dom";
import {ROUTE_RESERVATION_UPDATE} from "../../consts/Routes";

interface CalendarDrawerInterface {
    isView: boolean
    reservation?: ReservationInterface
    closeHandleAction: React.MouseEventHandler
}

const getPaymentColor = (paymentStatus?: PaymentStatusType): colorType => {
    switch (paymentStatus) {
        case "paid":
            return "green"
        case "unpaid":
            return "red"
        case "prepaid":
            return "yellow"
        default:
            return "red"
    }
}

const getPaymentMethodColor = (paymentMethod?: PaymentMethodType): colorType => {
    if ("booking" === paymentMethod) {
        return "blue"
    }

    return "light-orange"
}

const getReservationSource = (reservationSource?: ReservationSourceType): colorType => {
    if ("booking" === reservationSource) {
        return "blue"
    }

    return "light-orange"
}

const getRoomColor = (startDate: moment.Moment, endDate: moment.Moment, current: moment.Moment): colorType => {
    if (endDate.format(NORMAL_DATE_FORMAT) === current.format(NORMAL_DATE_FORMAT)) {
        return "light-red"
    }
    if (current > endDate) {
        return "yellow"
    }
    if (startDate > current) {
        return "light-orange"
    }
    if (startDate <= current && current <= endDate) {
        return "green"
    }

    return "red"
}

const CalendarDrawer = ({isView, reservation, closeHandleAction}: CalendarDrawerInterface) => {
    const current = moment()
    return (
        <div
            className={`${!isView && "hidden "} fixed top-0 left-0 right-0 z-10 p-4 overflow-x-hidden overflow-y-auto md:inset-0 md:h-full bg-gray-800/60`}
            onClick={closeHandleAction}>
            <div
                className={"w-[26rem] z-50 absolute right-0 top-0 bg-gray-800 border-l border-gray-700 h-full overflow-y-auto"}
                onClick={event => event.stopPropagation()}>
                <div className={"w-full h-full p-6"}>
                    <div className={"w-full flex items-center justify-end mb-1"}>
                        <button className={"text-gray-400 w-8 h-8 hover:text-gray-100 hover:bg-gray-600 rounded-lg"}
                                onClick={closeHandleAction}>
                            <FontAwesomeIcon icon={faClose}/>
                        </button>
                    </div>
                    <CalendarDrawerSection title={`Rezerwacja #${reservation?.id}`}>
                        <CalendarDrawerReservationLabel
                            text={"Całościowa kwota"}
                            badgeColor={getPaymentColor(reservation?.payment_status)}
                            badgeText={formattedPriceView(reservation?.total_price)}
                            badgePosition={"right"}
                        />
                        <CalendarDrawerReservationLabel
                            text={"Status płatności"}
                            badgeColor={getPaymentColor(reservation?.payment_status)}
                            badgeText={getTranslatablePaymentStatus(reservation?.payment_status, true)}
                            badgePosition={"right"}
                        />
                        <CalendarDrawerReservationLabel
                            text={"Metoda płatności"}
                            badgeColor={getPaymentMethodColor(reservation?.payment_method)}
                            badgeText={getTranslatablePaymentMethod(reservation?.payment_method, true)}
                            badgePosition={"right"}
                        />
                        <CalendarDrawerReservationLabel
                            text={"Źródło rezerwacji"}
                            badgeColor={getReservationSource(reservation?.reservation_source)}
                            badgeText={getTranslatableReservationSource(reservation?.reservation_source ?? "email", true)}
                            badgePosition={"right"}
                        />
                    </CalendarDrawerSection>
                    <CalendarDrawerSection title={"Zarezerwowane pokoje"}>
                        <>
                            {
                                reservation?.reserved_rooms.map((value) => {
                                    const startDate = moment(value.start_date)
                                    const endDate = moment(value.end_date)

                                    return <CalendarDrawerReservationLabel
                                        text={`${startDate.format(NORMAL_DATE_FORMAT)} - ${endDate.format(NORMAL_DATE_FORMAT)}`}
                                        badgeColor={getRoomColor(startDate, endDate, current)}
                                        badgeText={value.room_name}
                                        badgePosition={"left"}
                                        key={Math.random()}
                                    />
                                })
                            }
                        </>
                    </CalendarDrawerSection>
                    <CalendarDrawerSection title={"Dane klienta"}>
                        <>
                            {reservation?.reservation_guest.name &&
                                <CalendarDrawerReservationGuestLabel
                                    guestTitle={"Imię i nazwisko"}
                                    guestText={reservation.reservation_guest.name}
                                    icon={faNoteSticky}
                                    isEmail={false}
                                    isPhone={false}
                                />
                            }
                            {
                                reservation?.reservation_guest.phone_number && <CalendarDrawerReservationGuestLabel
                                    guestTitle={"Numer telefonu"}
                                    guestText={formatPhoneNumber(reservation.reservation_guest.phone_number)}
                                    icon={faSquarePhone}
                                    isEmail={false}
                                    isPhone={true}
                                />
                            }
                            {
                                reservation?.reservation_guest.email_address && <CalendarDrawerReservationGuestLabel
                                    guestTitle={"Adres email"}
                                    guestText={reservation.reservation_guest.email_address}
                                    icon={faEnvelope}
                                    isEmail={true}
                                    isPhone={false}
                                />
                            }
                        </>
                    </CalendarDrawerSection>
                    <CalendarDrawerSection title={"Notatki"}>
                        <CalendarDrawerReservationLabel
                            text={reservation?.description ?? "Brak notatek"}
                        />
                    </CalendarDrawerSection>
                    <Link
                        to={ROUTE_RESERVATION_UPDATE.replace(":reservationId", reservation?.id.toString() ?? "#")}>
                        <button
                            className={"border mb-6 border-gray-600 w-full h-10 rounded-lg hover:bg-gray-700 text-gray-400 text-sm cursor-pointer"}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} className={"mr-2"}/>
                            <span>Edytuj rezerwację</span>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CalendarDrawer