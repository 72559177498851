const colors = {
    'info': 'blue',
    'warning': 'yellow',
    'success': 'green',
    'danger': 'red'
}

export const buttonConfig: any = {
    info: {
        classes: "bg-blue-700 hover:bg-blue-800 focus:ring-blue-300"
    },
    warning: {
        classes: "bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-700"
    },
    success: {
        classes: "bg-green-600 hover:bg-green-700 focus:ring-green-800"
    },
    danger: {
        classes: "bg-red-600 hover:bg-red-700 focus:ring-red-900"
    }
}

export const badgeConfig: any = {
    info: {
        classes: "bg-blue-100 text-blue-800"
    },
    warning: {
        classes: "bg-yellow-100 text-yellow-800"
    },
    success: {
        classes: "bg-green-100 text-green-800"
    },
    danger: {
        classes: "bg-red-100 text-red-800"
    }
}

export type colorType = 'info' | 'warning' | 'success' | 'danger';

export const getColorFromType = (type: colorType) => {
    return colors[type];
}

export const parseToInt = (value: any): number => {
    const parsed = parseInt(value)
    if (isNaN(parsed)) {
        return 0;
    }
    return parsed;
}

export const parseToFloat = (value: any): number => {
    const parsed = parseFloat(value)
    if (isNaN(parsed)) {
        return 0.0;
    }

    return (parsed * 100.00) / 100.00;
}

export const fixedNullableValue = (value: string | undefined | null): string | null => {
    if (undefined === value || null === value) {
        return null
    }
    if (value.length < 1) {
        return null
    }

    return value;
}

export const fixedNullableValueFromResponse = (value: string | undefined | null): string | undefined => {
    if (undefined === value || null === value) {
        return undefined
    }
    if (value.length < 1) {
        return undefined
    }

    return value;
}