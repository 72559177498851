import {faAngleLeft, faAngleRight, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MouseEventHandler} from "react";

interface CalendarNavigationButtonInterface {
    direction: "left" | "right" | "now"
    action?: MouseEventHandler
}

const CalendarNavigationButton = ({direction, action}: CalendarNavigationButtonInterface) => {
    let classParameters: string = "bg-gray-700 py-2 px-4 hover:bg-gray-600 text-gray-400 hover:text-gray-300"
    let icon: IconDefinition | undefined = undefined;

    switch (direction) {
        case "left":
            classParameters += " rounded-l-lg"
            icon = faAngleLeft
            break
        case "right":
            classParameters += " rounded-r-lg"
            icon = faAngleRight
            break
    }

    return (
        <button
            className={classParameters}
            onClick={action}
        >
            {
                icon
                    ? <FontAwesomeIcon icon={icon}/>
                    : "teraz"
            }
        </button>
    )

}

export default CalendarNavigationButton