interface CardTitleInterface {
  text: string
}

const CardTitle = ({text}: CardTitleInterface) => {
  return (
    <div className={"w-full h-16 flex items-center justify-between px-4 border-b border-gray-700"}>
      <span className={"text-xl font-bold text-gray-200 first-letter:uppercase"}>{text}</span>
    </div>
  )
}

export default CardTitle