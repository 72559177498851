import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import sendRequest from "../../ApiManager/ApiManager";
import axios from "axios";
import {PaymentStatusType, ReservationSourceType} from "../../utils/TypeUtils";
import {ReservationGuestInterface} from "../../utils/Entities";

interface ReportReservedRoomItemInterface {
    room_name: string
    start_date: string
    end_date: string
}

export interface ReportReservedRoomInterface {
    check_in: ReportReservedRoomItemInterface[]
    check_out: ReportReservedRoomItemInterface[]
    during: ReportReservedRoomItemInterface[]
}

export interface ReportReservationInterface {
    id: number,
    payment_status: PaymentStatusType,
    reservation_source: ReservationSourceType,
    total_price: number,
    description?: string | null,
    reservation_guest: ReservationGuestInterface
    reserved_rooms: ReportReservedRoomInterface
}

interface initialStateInterface {
    reservations: ReportReservationInterface[]
}

const initialState: initialStateInterface = {
    reservations: []
}

const ReportManage = createSlice({
    name: 'reportManage',
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchReports.fulfilled, (state, action) => {
            state.reservations = action.payload
        })
    }
})


export const fetchReports = createAsyncThunk('get/reports', async (date: string, thunkAPI) => {
    try {
        const response = await sendRequest('get', '/reports/reservations?date=' + date);
        return response.data
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
})

export const ReportManageActions = ReportManage.actions;
export default ReportManage