import CalendarDayNumber from "../../atoms/calendar/CalendarDayNumber";
import CalendarDayName from "../../atoms/calendar/CalendarDayName";
import CalendarBadge, {colorType} from "../../atoms/calendar/CalendarBadge";
import {CalendarReservedRoomInterface} from "../../../utils/Entities";

export interface CalendarDayInterface {
    isSelectedMonth: boolean
    isNextDayInSelectedMonth: boolean
    isToday: boolean
    isSunday: boolean
    isBottomBorder: boolean
    dayName?: string
    dateNumber: string | number
    formattedDate: string,
    calendarReservedRooms: CalendarReservedRoomInterface[],
    openDrawerHandleAction: (reservationId: number) => void
}

const CalendarDay = ({
                         isSelectedMonth,
                         isNextDayInSelectedMonth,
                         isToday,
                         isSunday,
                         isBottomBorder,
                         dayName,
                         dateNumber,
                         formattedDate,
                         calendarReservedRooms,
                         openDrawerHandleAction
                     }: CalendarDayInterface) => {
    const backgroundParameters: string = isSelectedMonth ? "bg-gray-800" : "bg-gray-700"
    const borderBackgroundParameters: string = !isSelectedMonth && !isNextDayInSelectedMonth ? "border-gray-600" : "border-gray-700"
    let borderParameters: string = ""

    if (isBottomBorder) {
        borderParameters += "border-b"
    }
    if (!isSunday) {
        borderParameters += " border-r"
    }

    let classParameters = `min-h-[10rem] flex flex-col justify-between ${backgroundParameters} ${borderBackgroundParameters} ${borderParameters}`
    return (
        <div
            className={classParameters}>
            <div className={"text-center p-3"}>
                {dayName && <CalendarDayName text={dayName} isSelectedMonth={isSelectedMonth}/>}
            </div>
            <div className={"p-2 flex flex-row flex-wrap gap-2"} id={formattedDate}>
                {
                    calendarReservedRooms.map(calendarReservedRoom => {
                        let color: colorType = "red"
                        if ("booking" === calendarReservedRoom.reservationSource) {
                            color = "blue"
                        } else {
                            switch (calendarReservedRoom.paymentStatus) {
                                case "paid":
                                    color = "green"
                                    break;
                                case "unpaid":
                                    color = "red"
                                    break;
                                case "prepaid":
                                    color = "yellow"
                                    break;
                            }
                        }
                        return <CalendarBadge
                            color={color}
                            text={calendarReservedRoom.roomName}
                            action={() => {
                                openDrawerHandleAction(calendarReservedRoom.reservationId)
                            }}
                            key={Math.random()}
                        />

                    })
                }
            </div>
            <div className={"text-right p-3"}>
                <CalendarDayNumber text={dateNumber} isSelectedMonth={isSelectedMonth} isToday={isToday}/>
            </div>
        </div>
    )
}

export default CalendarDay