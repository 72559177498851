import React from "react";

interface InputErrorInterface {
    errorMessage?: string
}

const InputError = ({errorMessage}: InputErrorInterface) => {
    return (<div className={"text-red-500 text-sm h-5 flex items-center"}>
        {errorMessage && <p>{errorMessage}</p>}
    </div>)
}

export default InputError
