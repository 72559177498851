import {configureStore} from '@reduxjs/toolkit'
import thunk from "redux-thunk";
import roomManage from "./features/RoomManage";
import ManageReservation from "./features/ManageReservation";
import InvoiceManage from "./features/InvoiceManage";
import authorizationManage from "./features/AuthorizationManage";
import ReportManage from "./features/ReportManage";

const store = configureStore({
        reducer: {
            authorizationManage: authorizationManage.reducer,
            roomManage: roomManage.reducer,
            manageReservation: ManageReservation.reducer,
            invoiceManage: InvoiceManage.reducer,
            reportManage: ReportManage.reducer,
        },
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false
            }).concat(thunk)
    },
)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
