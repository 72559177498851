import TableHead from "../molecules/table/TableHead";
import TableTH from "../atoms/table/TableTH";
import TableBody from "../molecules/table/TableBody";
import TableBodyRow from "../molecules/table/TableBodyRow";
import TableTD from "../atoms/table/TableTD";
import Badge from "../atoms/Badge";
import Table from "./Table";
import {ReportDataInterface} from "../../hooks/useReport";

interface SummaryTableInterface {
  reportDataCollection: ReportDataInterface[]
  handleClickOnReservation: (reservationId: number) => void
}

const SummaryTable = ({reportDataCollection, handleClickOnReservation}: SummaryTableInterface) => {
  return <Table>
    <TableHead>
      <TableTH className={"w-1/12"}>#</TableTH>
      <TableTH className={"w-2/12"}>Status</TableTH>
      <TableTH className={"w-2/12"}>Pokoje</TableTH>
      <TableTH className={"w-2/12"}>Termin rezerwacji</TableTH>
      <TableTH className={"w-2/12"}>Status płatności</TableTH>
      <TableTH className={"w-2/12"}>Dane klienta</TableTH>
      <TableTH className={"w-3/12"}>Notatki</TableTH>
    </TableHead>
    <>
      {
        reportDataCollection.length === 0
          ? (
            <TableBody>
              <TableBodyRow>
                <TableTD colSpan={7} className={"text-center"}>
                  <Badge badgeType={"light-orange"} text={"Brak rezerwacji w wybranym okresie czasu"}/>
                </TableTD>
              </TableBodyRow>
            </TableBody>
          )
          : reportDataCollection
            .map(reportData => {
              return <TableBody border hover key={reportData.reservationId}>
                {
                  reportData.reservedRooms.map((reportReservedRoom, index) => {
                    return <TableBodyRow
                      key={Math.random()}
                      onClick={() => handleClickOnReservation(reportData.reservationId)}>
                      <>
                        {
                          0 === index && <TableTD rowSpan={reportData.reservedRooms.length} className={"w-1/12"}>
                            #{reportData.reservationId}
                          </TableTD>
                        }
                      </>
                      <TableTD>
                        <Badge
                          badgeType={reportReservedRoom.statusBadgeType}
                          text={reportReservedRoom.statusTranslatable}
                        />
                      </TableTD>
                      <TableTD className={"gap-1 flex flex-wrap justify-center items-center"}>
                        {reportReservedRoom.roomNames.map(roomName => {
                          return <Badge
                            badgeType={"default"}
                            text={roomName}
                            key={Math.random()}
                          />
                        })}
                      </TableTD>
                      <TableTD>
                        {reportReservedRoom.roomTerm}
                      </TableTD>
                      <>
                        {
                          0 === index && <TableTD rowSpan={reportData.reservedRooms.length}>
                            <Badge
                              badgeType={reportData.reservationPaymentStatusBadgeType}
                              text={reportData.reservationPaymentStatusTranslatable}
                            />
                          </TableTD>
                        }
                        {
                          0 === index && <TableTD rowSpan={reportData.reservedRooms.length}>
                            {reportData.guestData}
                          </TableTD>
                        }
                        {
                          0 === index && <TableTD rowSpan={reportData.reservedRooms.length}>
                            {reportData.description}
                          </TableTD>
                        }
                      </>
                    </TableBodyRow>
                  })
                }
              </TableBody>
            })
      }
    </>
  </Table>
}

export default SummaryTable