import {ReactElement} from "react";
import {TableBodyRowInterface} from "./TableBodyRow";

interface TableBodyInterface {
    children?: ReactElement<TableBodyRowInterface> | ReactElement<TableBodyRowInterface>[]
    border?: boolean
    hover?: boolean
}

const TableBody = ({children, border, hover}: TableBodyInterface) => {
    const borderClassName = border ? "border-b border-gray-700 last:border-none" : ""
  const hoverClassName = hover ? "hover:bg-gray-600 hover:text-gray-300" : ""

    return (
        <tbody className={`${borderClassName} ${hoverClassName} bg-gray-800 text-gray-400 text-sm`}>
        {children}
        </tbody>
    )
}

export default TableBody