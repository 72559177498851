import React from "react";
import Heading from "../../components/utils/Heading";
import Card from "../../components/card/Card";
import {useAppDispatch} from "../../hooks";
import ReservationForm from "../../components/form/pages/ReservationForm";
import {manageReservationActions} from "../../redux/features/ManageReservation";

function ReservationCreate() {
    const dispatch = useAppDispatch()


    dispatch(manageReservationActions.clearStore())
    dispatch(manageReservationActions.addReservedRoomRow({isDeleteButton: false}))

    return (
        <Card>
            <div>
                <Heading size={3} text={"Dodaj rezerwację"}/>
                <ReservationForm/>
            </div>
        </Card>
    )
}

export default ReservationCreate;