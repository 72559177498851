
export const validatorMessages = [
    {
        source: "The value you selected is not a valid choice.",
        target: "Ta wartość powinna być jedną z podanych opcji."
    },
    {
        source: "This field was not expected.",
        target: "Tego pola się nie spodziewano w formularzu."
    },
    {
        source: "This field is missing.",
        target: "To pole jest wymagane."
    },
    {
        source: "This value is not a valid date",
        target: "Ta wartość nie jest prawidłową datą"
    },
    {
        source: "This value is not a valid datetime",
        target: "Ta wartość nie jest prawidłową datą z godziną"
    },
    {
        source: "This value is not a valid email address",
        target: "Ta wartość nie jest prawidłowym adresem email."
    },
    {
        source: "This value should be",
        target: "Ta wartość powinna wynosić"
    },
    {
        source: "This value should be",
        target: "Ta wartość powinna wynosić"
    },
    {
        source: "This value should not be blank",
        target: "Ta wartość nie powinna być pusta"
    },
    {
        source: "This value should not be null",
        target: "Ta wartość nie powinna być nullem"
    },
    {
        source: "This value should be null",
        target: "Ta wartość powinna być nullem"
    },
    {
        source: "This value is not valid",
        target: "Ta wartość jest nieprawidłowa"
    },
    {
        source: "This value is not a valid URL",
        target: "Ta wartość nie jest prawidłowym adresem URL"
    },
    {
        source: "This value should be a valid number",
        target: "Ta wartość powinna być prawidłową liczbą"
    },
    {
        source: "This value should be less than or equal to",
        target: "Ta wartość powinna być mniejsza bądź równa"
    },
    {
        source: "This value should not be equal to",
        target: "Ta wartość nie powinna być równa"
    },
    {
        source: "One or more of the given values is invalid",
        target: "Jedna lub więcej z podanych wartości jest nieprawidłowa"
    },
    {
        source: "This value is too long. It should have",
        target: "Ta wartość jest zbyt długa. Powinna mieć maksymalnie"
    },
    {
        source: "character or less",
        target: "lub mniej znaków"
    },
    {
        source: "This value is too long. It should have",
        target: "Ta wartość jest zbyt długa. Powinna mieć maksymalnie"
    },
    {
        source: "characters or less",
        target: "lub mniej znaków"
    },
    {
        source: "This value is too short. It should have",
        target: "Ta wartość jest zbyt krótka. Powinna mieć minimum"
    },
    {
        source: "character or more",
        target: "lub więcej znaków"
    },
    {
        source: "This value is too short. It should have",
        target: "Ta wartość jest zbyt krótka. Powinna mieć minimum"
    },
    {
        source: "characters or more",
        target: "lub więcej znaków"
    },
    {
        source: "or less",
        target: "lub mniej"
    },
    {
        source: "or more",
        target: "lub więcej"
    },
]