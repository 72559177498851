import React from "react";
import HelpLabel from "./HelpLabel";
import ErrorLabel from "./ErrorLabel";

type selectOptions = {
    value: number | string;
    text: number | string;
    disabled?: boolean
}

interface SelectProps {
    name: string;
    title: string;
    options: selectOptions[];
    placeholder?: string;
    isRequired?: boolean;
    onChange?: React.ChangeEventHandler
    value?: string | number
    errorMessage?: string
}

function Select(selectProps: SelectProps) {
    const errorRing = selectProps.errorMessage && "ring-red-600 ring-2 "

    const renderOptions = () => {
        let options = [];
        for (let option of selectProps.options) {
            options.push(<option
                disabled={option.disabled}
                selected={option.disabled}
                hidden={option.disabled}
                key={Math.random()}
                value={option.value}>
                {option.text}
            </option>)
        }
        return options;
    }

    return (
        <div>
            <HelpLabel name={selectProps.name} title={selectProps.title} required={selectProps.isRequired}/>
            <select
                id={selectProps.name}
                name={selectProps.name}
                placeholder={selectProps.placeholder}
                required={selectProps.isRequired}
                onChange={selectProps.onChange}
                value={selectProps.value}
                className={errorRing+"border text-sm rounded-lg block w-full p-3 bg-gray-700 border-none placeholder-gray-400 text-gray-100 focus:ring-2 focus:ring-orange-400 outline-none"}
            >
                {renderOptions()}
            </select>
            <ErrorLabel errorMessage={selectProps.errorMessage}/>
        </div>
    );
}

export default Select;