import {messages} from "../../Settings";

const VALIDATOR_EXCEPTION = "ValidatorException"
const ROOM_NAME_ALREADY_TOKEN_EXCEPTION = "RoomNameAlreadyTakenException"
const ROOM_ALREADY_RESERVED_EXCEPTION = "RoomAlreadyReservedException"
const NO_SUCH_PROPERTY_EXCEPTION = "NoSuchPropertyException"
const MISSING_API_TOKEN_EXCEPTION = "MissingApiTokenException"
const INVALID_PHONE_NUMBER_EXCEPTION = "InvalidPhoneNumberException"

interface responseInterface {
    http_error?: number,
    type?: string,
    message?: string | [],
    error?: string
}

export const getResponseErrorMessage = (errorResponse: responseInterface): string => {
    let message = "undefined"
    const type = errorResponse?.type
    const error = errorResponse?.error

    if (VALIDATOR_EXCEPTION === type) {
        message = messages.errors.validation
    }
    if (ROOM_NAME_ALREADY_TOKEN_EXCEPTION === type) {
        message = messages.errors.roomNameAlreadyTaken
    }
    if (ROOM_ALREADY_RESERVED_EXCEPTION === type) {
        message = messages.errors.roomAlreadyReserved
    }
    if (NO_SUCH_PROPERTY_EXCEPTION === type) {
        message = messages.errors.validation
    }
    if (MISSING_API_TOKEN_EXCEPTION === type) {
        message = messages.errors.missingApiToken
    }
    if (INVALID_PHONE_NUMBER_EXCEPTION === type) {
        message = messages.errors.invalidPhoneNumber
    }


    if ("Invalid credentials." === error) {
        message = messages.authorization.invalidLogin;
    }

    return message
}