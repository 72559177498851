import React from "react";

interface CardProps {
    children?: JSX.Element | JSX.Element[]
}

function Card(cardProps: CardProps) {
    return (
        <div className={"w-full mb-8 p-4 border border-gray-800 bg-gray-800 rounded-lg shadow-lg shadow-gray-800/50"}>
            <div className={"w-full"}>
                {cardProps.children}
            </div>
        </div>
    )
}

export default Card;