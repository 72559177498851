import {ReservationGuestInterface, ReservationInterface, ReservedRoomInterface} from "../utils/Entities";
import {badgeType} from "../components/atoms/Badge";
import {
  getBadgeTypeColorForReservationPaymentStatus, getReservedRoomStatus
} from "../utils/SummaryUtils";
import {formatPhoneNumber, getTranslatablePaymentStatus} from "../utils/Utils";
import moment from "moment";
import {NORMAL_DATE_FORMAT} from "../helper/DateHelper";
import {useState} from "react";

export interface ReportReservedRoomInterface {
  statusBadgeType: badgeType
  statusTranslatable: string
  statusSearch: string
  roomNames: string[]
  roomTerm: string
}

export interface ReportDataInterface {
  reservationId: number
  reservedRooms: ReportReservedRoomInterface[]
  reservationPaymentStatusBadgeType: badgeType
  reservationPaymentStatusTranslatable: string
  guestData: string
  description: string
}

const isReservedRoomInSameDate = (reservedRooms: ReservedRoomInterface[]): boolean => {
  if (reservedRooms.length <= 1) {
    return true
  }
  const firstReservedRoomStartDate = reservedRooms[0].start_date
  const firstReservedRoomEndDate = reservedRooms[0].end_date

  reservedRooms.map(reservedRoom => {
    const startDate = reservedRoom.start_date
    const endDate = reservedRoom.end_date

    if (firstReservedRoomStartDate !== startDate || firstReservedRoomEndDate !== endDate) {
      return false
    }
  })

  return false
}

const getReservedRooms = (reservedRooms: ReservedRoomInterface[]): string[] => {
  const roomNames: string[] = []

  reservedRooms.map(reservedRoom => {
    roomNames.push(reservedRoom.room_name)
  })

  return roomNames
}

const getReservedRoomsByDates = (reservedRooms: ReservedRoomInterface[], startDate: string, endDate: string): string[] => {
  const roomNames: string[] = []

  reservedRooms.map(reservedRoom => {
    if (startDate === reservedRoom.start_date && endDate === reservedRoom.end_date) {
      roomNames.push(reservedRoom.room_name)
    }
  })

  return roomNames
}

const getReservedRoomTerms = (reservedRoom: ReservedRoomInterface): string => {
  const startDate = moment(reservedRoom.start_date).format(NORMAL_DATE_FORMAT)
  const endDate = moment(reservedRoom.end_date).format(NORMAL_DATE_FORMAT)

  return `${startDate} - ${endDate}`
}

const getReservationGuest = (reservationGuest: ReservationGuestInterface): string => {
  const name = reservationGuest.name
  const phoneNumber = reservationGuest.phone_number


  if (name && phoneNumber) {
    return `${name}, ${formatPhoneNumber(phoneNumber)}`
  }
  if (phoneNumber && !name) {
    return formatPhoneNumber(phoneNumber)
  }
  if (name && !phoneNumber) {
    return name
  }

  return "Nie podano"
}

const useReport = () => {
  const reportDataCollection: ReportDataInterface[] = []
  const [reservations, setReservations] = useState<ReservationInterface[]>([])
  const [internalCurrentDate, setInternalCurrentDate] = useState<string>()

  const initiateMapReportData = (reservations: ReservationInterface[], currentDate?: string) => {
    setReservations(reservations)
    setInternalCurrentDate(
      currentDate?.length !== 0
        ? currentDate
        : undefined
    )
  }

  reservations.map(reservation => {
    const reservedRoomInSameDate = isReservedRoomInSameDate(reservation.reserved_rooms)

    if (reservedRoomInSameDate) {
      const {
        translatableReservedRoomStatus,
        searchReservedRoomStatus,
        badgeTypeColorForReservedRoomStatus
      } = getReservedRoomStatus(
        reservation.reserved_rooms[0].start_date,
        reservation.reserved_rooms[0].end_date,
        internalCurrentDate
      )

      const reportData: ReportDataInterface = {
        reservationId: reservation.id,
        reservedRooms: [
          {
            statusBadgeType: badgeTypeColorForReservedRoomStatus,
            statusSearch: searchReservedRoomStatus,
            statusTranslatable: translatableReservedRoomStatus,
            roomNames: getReservedRooms(reservation.reserved_rooms),
            roomTerm: getReservedRoomTerms(reservation.reserved_rooms[0]),
          }
        ],
        reservationPaymentStatusBadgeType: getBadgeTypeColorForReservationPaymentStatus(reservation.payment_status),
        reservationPaymentStatusTranslatable: getTranslatablePaymentStatus(reservation.payment_status, true),
        guestData: getReservationGuest(reservation.reservation_guest),
        description: reservation.description ?? "-"
      }

      reportDataCollection.push(reportData)

      return
    }

    const cacheCollection: string[] = []
    const reportReservedRoomCollection: ReportReservedRoomInterface[] = []
    reservation.reserved_rooms.map(reservedRoom => {
      const cacheKey = `${reservation.id}${reservedRoom.start_date}${reservedRoom.end_date}`

      if (!cacheCollection.includes(cacheKey)) {
        const {
          translatableReservedRoomStatus,
          searchReservedRoomStatus,
          badgeTypeColorForReservedRoomStatus
        } = getReservedRoomStatus(
          reservedRoom.start_date,
          reservedRoom.end_date,
          internalCurrentDate
        )

        const reportReservedRoom: ReportReservedRoomInterface = {
          statusBadgeType: badgeTypeColorForReservedRoomStatus,
          statusSearch: searchReservedRoomStatus,
          statusTranslatable: translatableReservedRoomStatus,
          roomNames: getReservedRoomsByDates(reservation.reserved_rooms, reservedRoom.start_date, reservedRoom.end_date),
          roomTerm: getReservedRoomTerms(reservedRoom),
        }

        reportReservedRoomCollection.push(reportReservedRoom)
        cacheCollection.push(cacheKey)
      }
    })

    const reportData: ReportDataInterface = {
      reservationId: reservation.id,
      reservedRooms: reportReservedRoomCollection,
      reservationPaymentStatusBadgeType: getBadgeTypeColorForReservationPaymentStatus(reservation.payment_status),
      reservationPaymentStatusTranslatable: getTranslatablePaymentStatus(reservation.payment_status, true),
      guestData: getReservationGuest(reservation.reservation_guest),
      description: reservation.description ?? "-"
    }

    reportDataCollection.push(reportData)
  })

  return {reportDataCollection, initiateMapReportData}
}

export default useReport