import {requestMethodtype} from "../utils/TypeUtils";
import axios from "axios";
import {localStorageLocation} from "../Settings";

const sendRequest = async (
  requestMethod: requestMethodtype,
  endpoint: string,
  body?: object,
  noAuthorization?: boolean
) => {

  let headers = {
    'Content-Type': 'application/json'
  }

  if (undefined === noAuthorization || !noAuthorization) {
    const storage = localStorage.getItem(localStorageLocation);
    const token = JSON.parse(storage ?? "")?.api_token;

    headers = {
      ...headers,
      ...{
        'X-AUTH-TOKEN': token,
      }
    }
  }

  let requestConfig = {
    method: requestMethod.toLowerCase(),
    baseURL: process.env.REACT_APP_API_BASE_URL + '/api/v1',
    url: endpoint,
    headers: headers
  }
  if (body) {
    requestConfig = {
      ...requestConfig,
      ...{
        data: body
      }
    }
  }

  return await axios.request(requestConfig);

}

export const downloadInvoiceRequest = async (
  invoiceId: number
) => {
  const storage = localStorage.getItem(localStorageLocation);
  const token = JSON.parse(storage ?? "")?.api_token;

  const headers = {
    'X-AUTH-TOKEN': token,
  }

  let requestConfig = {
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL + '/api/v1',
    url: '/invoices' + invoiceId + '/download',
    headers: headers
  }

  return await axios.request(requestConfig);
}

export default sendRequest