import {localStorageLocation} from "../Settings";

export default function useAuthorization() {
    const getToken: () => string | null = () => {
        const vrUser = localStorage.getItem("vr_user");
        if ("undefined" === vrUser || null === vrUser) {
            return undefined;
        }

        const vrUserParse = JSON.parse(vrUser);

        return vrUserParse?.api_token;
    }

    const getUsername: () => string | null = () => {
        const vrUser = localStorage.getItem(localStorageLocation);
        if ("undefined" === vrUser || null === vrUser) {
            return null;
        }

        const vrUserParse = JSON.parse(vrUser);

        return vrUserParse?.first_name + " " + vrUserParse?.last_name;
    }

    const clearToken = () => {
        localStorage.removeItem("vr_user")
    }

    const getRoles = () => {
        const vrUser = localStorage.getItem("vr_user");
        if ("undefined" === vrUser || null === vrUser) {
            return undefined;
        }

        const vrUserParse = JSON.parse(vrUser);

        return vrUserParse?.roles;
    }

    const isUserLoggedIn = () => {
        return null !== getToken();
    }

    return {getToken, getRoles, isUserLoggedIn, clearToken, getUsername};
}