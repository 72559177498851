import React, {useState} from "react";

interface ModalPropsInterface {
  message: string,
  confirmAction?: React.MouseEventHandler<HTMLButtonElement>
}

const Modal = (modalProps: ModalPropsInterface) => {
  const [visible, setVisible] = useState(true)

  const handleHiddenModal = (event: React.MouseEvent) => {
    setVisible(false)
  }

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    modalProps.confirmAction?.(event)

    setVisible(false)
  }

  const hidden = visible ? "" : "hidden"

  return (
    <div id="popup-modal" tabIndex={-1}
         className={hidden + " fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-gray-800 bg-opacity-80 flex items-center justify-center"}

    >
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div className="relative rounded-lg shadow bg-gray-700">
          <button type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent rounded-lg text-lg p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                  data-modal-toggle="popup-modal"
                  onClick={handleHiddenModal}
          >
            <i className="fa-solid fa-xmark"></i>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 text-center">
            <span className={"text-white"}>
              <i className="fa-solid fa-triangle-exclamation fa-4x"></i>
            </span>
            <h3 className="mt-4 mb-5 text-lg font-normal text-gray-300">
              {modalProps.message}
            </h3>
            <button
              data-modal-toggle="popup-modal"
              type="button"
              className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              onClick={handleConfirm}
            >
              Tak, usuń
            </button>
            <button
              data-modal-toggle="popup-modal"
              type="button"
              className="focus:ring-4 focus:outline-none rounded-lg border text-sm font-medium px-5 py-2.5 focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:text-white hover:bg-gray-600 focus:ring-gray-600"
              onClick={handleHiddenModal}
            >
              Nie, anuluj
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal