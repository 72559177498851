import React from "react";

interface InputLabelInterface {
    inputName?: string
    label: string
    isRequired: boolean
}

const InputLabel = ({inputName, label, isRequired}: InputLabelInterface) => {
    const requiredElement = <span className={"text-red-600"}>*</span>
    return (
        <label htmlFor={inputName} className={"text-gray-200 font-medium text-sm h-5 flex items-center"}>
            {label}
            {isRequired && requiredElement}
        </label>
    )
}

export default InputLabel